import { Box } from "@mui/material";
import StepperNLC from "src/modules/globals/components/stepperNLC";
import OnboardingStep1 from "../onboardingStep1";
import OnboardingStep2 from "../onboardingStep2";
import OnboardingStep3 from "../onboardingStep3";
import OnboardingStep4 from "../onboardingStep4";
import OnboardingStep5 from "../onboardingStep5";
import { OnboardingTaxonomies, StoreOnboardingDto } from "../../interface";
import { useEffect, useState } from "react";

interface OnboardingStepsProps {
  onboardingTaxonomies?: OnboardingTaxonomies;
  onSaveOnboarding?: (data: StoreOnboardingDto) => void;
}

const OnboardingSteps = ({
  onboardingTaxonomies,
  onSaveOnboarding,
}: OnboardingStepsProps) => {
  const [onboardingCreateDto, setOnboardingCreateDto] =
    useState<StoreOnboardingDto>({
      industry: "",
      other_description: "",
      design_style: [],
      production_order: [],
      graphic_design: [],
      video_design: [],
      communication_frequency: "",
      assistance_needed: true,
      successful_definition: "",
    });

  useEffect(() => {
    setOnboardingCreateDto((prev) => ({
      ...prev,
      production_order: onboardingTaxonomies?.production ?? [],
    }));
  }, [onboardingTaxonomies]);

  const steps = [
    <OnboardingStep1 key={0} />,
    <OnboardingStep2
      onboardingCreateDto={onboardingCreateDto}
      setOnboardingCreateDto={setOnboardingCreateDto}
      key={1}
    />,
    // <OnboardingStep3
    //   onboardingCreateDto={onboardingCreateDto}
    //   setOnboardingCreateDto={setOnboardingCreateDto}
    //   key={2}
    // />,
    <OnboardingStep4
      onboardingTaxonomies={onboardingTaxonomies}
      onboardingCreateDto={onboardingCreateDto}
      setOnboardingCreateDto={setOnboardingCreateDto}
      key={3}
    />,
    <OnboardingStep5
      onboardingTaxonomies={onboardingTaxonomies}
      onboardingCreateDto={onboardingCreateDto}
      setOnboardingCreateDto={setOnboardingCreateDto}
      key={4}
    />,
  ];
  return (
    <StepperNLC
      onSave={() => onSaveOnboarding?.(onboardingCreateDto)}
      variant="onboarding"
      steps={steps}
    />
  );
};

export default OnboardingSteps;
