export const tokens = {
  action: {
    copyLink: "action.copyLink",
    delete: "action.delete",
  },
  common: {
    languageChanged: "common.languageChanged",
    name: "common.name",
    industry: "common.industry",
  },
  create: {
    account: "create.account",
    announcement: "create.announcement",
    brand: "create.brand",
    category: "create.category",
    coupon: "create.coupon",
    course: "create.course",
    dashboardFeed: "create.dashboardFeed",
    department: "create.department",
    maintenance: "create.maintenance",
    request: "create.request",
    team: "create.team",
    user: "create.user",
    organization: "create.organization",
  },
  nav: {
    support: "nav.support",
    recycleBin: "nav.recycleBin",
    billing: "nav.billing",
    academy: "nav.academy",
    account: "nav.account",
    accounts: "nav.accounts",
    all: "nav.all",
    analytics: "nav.analytics",
    announcements: "nav.announcements",
    auth: "nav.auth",
    automatedSuggestion: "nav.automatedSuggestion",
    blacklist: "nav.blacklist",
    blog: "nav.blog",
    brands: "nav.brands",
    browse: "nav.browse",
    calendar: "nav.calendar",
    categories: "nav.categories",
    chat: "nav.chat",
    checkout: "nav.checkout",
    concepts: "nav.concepts",
    contact: "nav.contact",
    coupons: "nav.coupons",
    course: "nav.course",
    courses: "nav.courses",
    create: "nav.create",
    crypto: "nav.crypto",
    users: "nav.users",
    dashboard: "nav.dashboard",
    dashboardFeed: "nav.dashboardFeed",
    details: "nav.details",
    departments: "nav.departments",
    ecommerce: "nav.ecommerce",
    edit: "nav.edit",
    error: "nav.error",
    feed: "nav.feed",
    fileManager: "nav.fileManager",
    files: "nav.files",
    finance: "nav.finance",
    fleet: "nav.fleet",
    forgotPassword: "nav.forgotPassword",
    helpCenter: "nav.helpCenter",
    inactiveRequests: "nav.inactiveRequests",
    inspiration: "nav.inspiration",
    invoiceList: "nav.invoices",
    jobList: "nav.jobList",
    kanban: "nav.kanban",
    list: "nav.list",
    login: "nav.login",
    logistics: "nav.logistics",
    mail: "nav.mail",
    maintenance: "nav.maintenance",
    management: "nav.management",
    orderList: "nav.orders",
    organizations: "nav.organizations",
    overview: "nav.overview",
    pages: "nav.pages",
    paymentLogs: "nav.paymentLogs",
    postCreate: "nav.postCreate",
    postDetails: "nav.postDetails",
    postList: "nav.postList",
    pricing: "nav.pricing",
    products: "nav.products",
    productList: "nav.products",
    profile: "nav.profile",
    register: "nav.register",
    requests: "nav.requests",
    teams: "nav.teams",
    reports: "nav.reports",
    resetPassword: "nav.resetPassword",
    save: "nav.save",
    socialMedia: "nav.socialMedia",
    teamAssignment: "nav.teamAssignment",
    tool: "nav.tool",
    tools: "nav.tools",
    verifyCode: "nav.verifyCode",
    notifications: "nav.notifications",
    settings: "nav.settings",
  },
};
