import axios from "axios";

const createAxiosInstance = () =>
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

export const getPublicAPI = createAxiosInstance;

export const getSecureApi = (token: string) => {
  const instance = createAxiosInstance();
  instance.interceptors.request.use((axiosRequest: any) => {
    if (token) {
      const headers = axiosRequest.headers ? axiosRequest.headers : {};
      axiosRequest.headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return axiosRequest;
  });
  return instance;
};
