import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography, useTheme } from "@mui/material";

import { CSSProperties, ReactNode } from "react";
import { AlertType } from "../../context/alertContext";

interface AlertNLCProps {
  sx?: CSSProperties;
  messages?: { title?: string; message: string }[];
  icon?: ReactNode;
  onClose?: () => void;
  type?: AlertType;
}

const AlertNLC = ({
  sx,
  messages,
  icon,
  onClose,
  type = AlertType.PRIMARY,
}: AlertNLCProps) => {
  const { palette } = useTheme();
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{
        width: "100%",
        minHeight: "64px",
        padding: "10px 8px 10px 16px",
        borderRadius: "8px",
        backgroundColor: palette[type][100],
        opacity: 0.8,
        ...sx,
      }}
    >
      <Stack direction={"row"}>
        {icon && <Box sx={{ marginRight: "8px" }}>{icon}</Box>}
        <Stack spacing={"4px"} direction={"column"}>
          {messages?.map((message, index) => (
            <Stack direction={"row"} key={index}>
              {message.title && (
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: palette[type][800],
                    fontSize: "14px",
                  }}
                >
                  {message.title} -{" "}
                </Typography>
              )}{" "}
              <Typography sx={{ fontSize: "14px", color: palette[type][800] }}>
                {" "}
                {message.message}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      {onClose && (
        <FontAwesomeIcon
          cursor={"pointer"}
          color={palette[type][800]}
          icon={["far", "close"]}
        />
      )}
    </Stack>
  );
};

export default AlertNLC;
