import { getPublicAPI, getSecureApi } from "../../config/axios";
import {
  IMPERSONATE_STORAGE_KEY,
  JWT_STORAGE_KEY,
} from "../../const/storageKeys";

const useAxios = () => {
  let token = sessionStorage.getItem(JWT_STORAGE_KEY);
  const impersonateToken = sessionStorage.getItem(IMPERSONATE_STORAGE_KEY);
  if (impersonateToken && impersonateToken != "") {
    token = impersonateToken;
  } else {
    token = sessionStorage.getItem(JWT_STORAGE_KEY);
  }
  return token && token != "" ? getSecureApi(token) : getPublicAPI();
};

export default useAxios;
