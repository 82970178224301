import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import DropdownListItemNLC from "src/modules/globals/components/dropdownListItemNLC";
import DropdownNLC from "src/modules/globals/components/dropdownNLC";
import RadioNLC from "src/modules/globals/components/radioNLC";
import TextFieldNLC from "src/modules/globals/components/textFieldNLC/Index";
import TextareaNLC from "src/modules/globals/components/textareaNLC";
import { StoreOnboardingDto, OnboardingTaxonomies } from "../../interface";

interface OnboardingStep5Props {
  onboardingCreateDto: StoreOnboardingDto;
  setOnboardingCreateDto: Dispatch<SetStateAction<StoreOnboardingDto>>;
  onboardingTaxonomies?: OnboardingTaxonomies;
}

const OnboardingStep5 = ({
  onboardingCreateDto,
  setOnboardingCreateDto,
  onboardingTaxonomies,
}: OnboardingStep5Props) => {
  const { palette } = useTheme();
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const frequency: { label: string; value: string }[] = [
    {
      label: "A few times a day",
      value: "day",
    },
    {
      label: "A few times a week",
      value: "week",
    },
    {
      label: "A few times a month",
      value: "month",
    },
  ];

  const handleSelectFrequency = (value: string) => {
    setOnboardingCreateDto((prev) => ({
      ...prev,
      communication_frequency: value,
    }));
    setSelectOpen(false);
  };

  const handleNeedAssistant = (value: boolean) => {
    setOnboardingCreateDto((prev) => ({
      ...prev,
      assistance_needed: value,
    }));
  };

  const handleChangeSuccessfulDefinition = (value: string) => {
    setOnboardingCreateDto((prev) => ({
      ...prev,
      successful_definition: value,
    }));
  };

  return (
    <Stack
      maxWidth={"520px"}
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography fontSize={"24px"} fontWeight={600} textAlign={"center"}>
        Define our relationship!
      </Typography>
      <Typography
        fontSize={"14px"}
        color={palette.text.secondary}
        fontWeight={400}
        textAlign={"center"}
      >
        We understand how important it is to properly manage expectations. Let
        us know how you envision our relationship.
      </Typography>
      <Box
        sx={{ alignSelf: "flex-start", width: "100%" }}
        marginTop={"24px"}
        borderTop={`1px solid ${palette.grey[50]}`}
        paddingTop={"24px"}
      >
        <Typography fontSize={"16px"} fontWeight={500}>
          How often would you like to communicate with your team?
        </Typography>
        <DropdownNLC
          sx={{ marginTop: "16px" }}
          open={selectOpen}
          width="100%"
          changeDropdown={() => setSelectOpen(!selectOpen)}
          activator={
            <TextFieldNLC
              selectable
              value={onboardingCreateDto?.communication_frequency}
              onClick={() => setSelectOpen(!selectOpen)}
              label="Select frequency"
              icon={<FontAwesomeIcon icon={["far", "smile"]} />}
            />
          }
        >
          {onboardingTaxonomies?.communication_frequencies.map((item) => (
            <DropdownListItemNLC
              onClick={() => handleSelectFrequency(item)}
              key={item}
              title={item}
            />
          ))}
        </DropdownNLC>
        <Typography marginTop={"24px"} fontSize={"16px"} fontWeight={500}>
          Do you need assistance coming up with design ideas?
        </Typography>
        <RadioNLC
          onSelectItem={(value) => handleNeedAssistant(value.value == "yes")}
          sx={{ marginTop: "16px" }}
          direction="row"
          selected={onboardingCreateDto?.assistance_needed ? "yes" : "no"}
          options={[
            { title: "Yes", value: "yes" },
            { title: "No", value: "no" },
          ]}
        />
        <Typography marginTop={"24px"} fontSize={"16px"} fontWeight={500}>
          What is your definition of a successful relationship with NLC?
        </Typography>
        <Box sx={{ marginTop: "16px" }} />
        <TextareaNLC
          placeholder="Share your view on a successful design relationship"
          value={onboardingCreateDto?.successful_definition}
          onChange={handleChangeSuccessfulDefinition}
        />
      </Box>
    </Stack>
  );
};

export default OnboardingStep5;
