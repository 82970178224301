import type { FC, ReactNode } from "react";
import { createContext, useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { Issuer } from "src/utils/auth";
import { authApi } from "../../api/auth";
import { User } from "src/types/user";
import { JWT_STORAGE_KEY } from "src/modules/core/const/storageKeys";
import { Profile } from "src/modules/globals/interfaces/profile";

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: Profile | null;
}

enum ActionType {
  INITIALIZE = "INITIALIZE",
  SIGN_IN = "SIGN_IN",
  SIGN_UP = "SIGN_UP",
  SIGN_OUT = "SIGN_OUT",
}

type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    user: Profile | null;
  };
};

type SignInAction = {
  type: ActionType.SIGN_IN;
  payload: {
    user: Profile | null;
  };
};

type SignUpAction = {
  type: ActionType.SIGN_UP;
  payload: {
    user: Profile | null;
  };
};

type SignOutAction = {
  type: ActionType.SIGN_OUT;
};

type Action = InitializeAction | SignInAction | SignUpAction | SignOutAction;

type Handler = (state: State, action: any) => State;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  SIGN_IN: (state: State, action: SignInAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SIGN_UP: (state: State, action: SignUpAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SIGN_OUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export interface AuthContextType extends State {
  issuer: Issuer.JWT;
  signIn: (email: string, password: string) => Promise<any>;
  signUp: (email: string, name: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  issuer: Issuer.JWT,
  signIn: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async (): Promise<void> => {
    try {
      const accessToken = window.sessionStorage.getItem(JWT_STORAGE_KEY);

      if (accessToken) {
        const user = await authApi.me({ accessToken });

        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            user: user.data?.data ?? null,
          },
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [dispatch]);

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const signIn = useCallback(
    async (email: string, password: string): Promise<any> => {
      const { data, isError, error } = await authApi.signIn({
        email,
        password,
      });
      if (isError) {
        throw new Error(error);
      }
      const user = await authApi.me({
        accessToken: data?.data?.accessToken ?? "",
      });

      sessionStorage.setItem(JWT_STORAGE_KEY, data?.data?.accessToken ?? "");

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          user: user.data?.data ?? null,
        },
      });
      return user;
    },
    [dispatch],
  );

  const signUp = useCallback(
    async (email: string, name: string, password: string): Promise<void> => {
      const { accessToken } = await authApi.signUp({ email, name, password });
      const user = await authApi.me({ accessToken });

      sessionStorage.setItem(JWT_STORAGE_KEY, accessToken);

      dispatch({
        type: ActionType.SIGN_UP,
        payload: {
          user: user.data?.data ?? null,
        },
      });
    },
    [dispatch],
  );

  const signOut = useCallback(async (): Promise<void> => {
    sessionStorage.removeItem(JWT_STORAGE_KEY);
    dispatch({ type: ActionType.SIGN_OUT });
  }, [dispatch]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        signIn,
        signUp,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
