import { Stack, Typography, useTheme } from "@mui/material";
import { ReactNode } from "react";
import AvatarNLC from "../avatarNLC";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BadgeNLC from "../badgeNLC";
import CheckboxNLC from "../checkboxNLC";

interface DropdownListItemNLCProps {
  hasCheckbox?: boolean;
  hasEndSelected?: boolean;
  badge?: string;
  icon?: ReactNode;
  iconPosition?: "left" | "right";
  selected?: boolean;
  onClick?: () => void;
  title?: string;
  subTitle?: string;
  img?: string;
  disabled?: boolean;
  cursor?: string;
  textColor?: string;
}

interface DropdownListItemNLCColorTheme {
  bgColor?: string;
  bgColorHover?: string;
  bgColorSelected?: string;
  bgColorDisabled?: string;
}

const DropdownListItemNLC = ({
  hasCheckbox,
  badge,
  icon,
  iconPosition = "left",
  selected,
  hasEndSelected,
  onClick,
  title = "",
  subTitle,
  img,
  disabled,
  cursor = "pointer",
  textColor,
}: DropdownListItemNLCProps) => {
  const { palette } = useTheme();

  const getColor = (): DropdownListItemNLCColorTheme => {
    switch (palette.mode) {
      default:
        return {
          bgColor: palette.background.paper,
          bgColorHover: palette.secondary[50],
          bgColorSelected: palette.primary[50],
          bgColorDisabled: "white",
        };
    }
  };

  return (
    <Stack
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        disabled ? {} : onClick?.();
      }}
      width={"100%"}
      alignItems={"center"}
      justifyContent={"justify-between"}
      sx={{
        cursor: cursor,
        backgroundColor: disabled
          ? getColor().bgColorDisabled
          : selected
          ? getColor().bgColorSelected
          : getColor().bgColor,
        opacity: disabled ? 0.3 : 1,
        "&:hover":
          !disabled && !selected
            ? {
                backgroundColor: getColor().bgColorHover,
              }
            : {},
        "&:disabled": {
          backgroundColor: getColor().bgColorDisabled,
        },
        "&:selected": {
          backgroundColor: getColor().bgColorSelected,
        },
      }}
      padding={badge || icon || hasCheckbox || img ? "8px 16px" : "8px 16px"}
      direction={"row"}
      spacing={"8px"}
    >
      <Stack
        flex={1}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        spacing={"8px"}
      >
        {hasCheckbox && (
          <CheckboxNLC
            value={selected ?? false}
            onChange={(val) => onClick?.()}
          />
        )}
        {icon && !img && iconPosition === "left" ? icon : null}
        {img && <AvatarNLC size="24px" src={img} />}
        <Typography
          textAlign={"left"}
          fontSize={14}
          fontWeight={500}
          color={textColor ?? palette.text.primary}
        >
          {title}
        </Typography>
        {subTitle && (
          <Typography
            fontFamily={"Inter"}
            color={palette.text.secondary}
            fontSize={13}
            fontWeight={400}
          >
            {subTitle}
          </Typography>
        )}
        {icon && !img && iconPosition === "right" ? icon : null}
        {badge && <BadgeNLC size="sm" text={badge} />}
      </Stack>
      {hasEndSelected && selected ? (
        <FontAwesomeIcon
          width={"16px"}
          height={"14px"}
          color={palette.success.main}
          icon={["fas", "check"]}
        />
      ) : null}
    </Stack>
  );
};

export default DropdownListItemNLC;
