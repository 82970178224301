import { useEffect, type FC, type ReactNode } from "react";
import PropTypes from "prop-types";
import { withAuthGuard } from "src/hocs/with-auth-guard";
import { useSettings } from "src/hooks/use-settings";
import { useSections } from "../../modules/globals/layout/dashboard/config";
import { HorizontalLayout } from "./horizontal-layout";
import { VerticalLayout } from "./vertical-layout";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";

interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = withAuthGuard((props) => {
  const settings = useSettings();
  const sections = useSections();
  const { isAuthenticated, isInitialized, signOut } = useAuth();
  useEffect(() => {
    if (isInitialized && !isAuthenticated) {
      signOut();
    }
  }, [isAuthenticated, isInitialized]);

  if (settings.layout === "horizontal") {
    return (
      <HorizontalLayout
        sections={sections}
        navColor={settings.navColor}
        {...props}
      />
    );
  }

  return (
    <VerticalLayout
      sections={sections}
      navColor={settings.navColor}
      {...props}
    />
  );
});

Layout.propTypes = {
  children: PropTypes.node,
};
