import type { FC } from "react";
import { subHours, subMinutes } from "date-fns";
import Users03Icon from "@untitled-ui/icons-react/build/esm/Users03";
import { IconButton, SvgIcon, Tooltip } from "@mui/material";
import { usePopover } from "src/hooks/use-popover";
import { ContactsPopover } from "./contacts-popover";

const now = new Date();

interface Contact {
  id: string;
  avatar: string;
  isActive: boolean;
  lastActivity?: number;
  name: string;
}

const useContacts = (): Contact[] => {
  return [
    {
      id: "5e8891ab188cd2855e6029b7",
      avatar: "/assets/avatars/avatar-alcides-antonio.png",
      isActive: true,
      lastActivity: now.getTime(),
      name: "Alcides Antonio",
    },
    {
      id: "5e887a62195cc5aef7e8ca5d",
      avatar: "/assets/avatars/avatar-marcus-finn.png",
      isActive: false,
      lastActivity: subHours(now, 2).getTime(),
      name: "Marcus Finn",
    },
    {
      id: "5e887ac47eed253091be10cb",
      avatar: "/assets/avatars/avatar-jenna-ortega.png",
      isActive: false,
      lastActivity: subMinutes(now, 15).getTime(),
      name: "Jenna Ortega",
    },
    {
      id: "93037484135",
      avatar: "/assets/avatars/avatar-keanu-reeves.png",
      isActive: true,
      lastActivity: now.getTime(),
      name: "Keanu Reeves",
    },
    {
      id: "52125011123",
      avatar: "/assets/avatars/avatar-harrison-ford.png",
      isActive: true,
      lastActivity: now.getTime(),
      name: "Harrison Ford",
    },
  ];
};

export const ContactsButton: FC = () => {
  const popover = usePopover<HTMLButtonElement>();
  const contacts = useContacts();

  return (
    <>
      <Tooltip title="Contacts">
        <IconButton onClick={popover.handleOpen} ref={popover.anchorRef}>
          <SvgIcon>
            <Users03Icon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <ContactsPopover
        anchorEl={popover.anchorRef.current}
        contacts={contacts}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
