import { ReactNode, useEffect, useState } from "react";
import OnboardingContext from "../../context/onboardingContext";
import OnboardingRepository from "../../repository/onboardingRepository";
import { OnboardingTaxonomies, StoreOnboardingDto } from "../../interface";
import { paths } from "src/paths";

interface OnboardingProviderProps {
  children: ReactNode;
}

const OnboardingProvider = ({ children }: OnboardingProviderProps) => {
  const [onboardingTaxonomies, setOnboardingTaxonomies] =
    useState<OnboardingTaxonomies>();
  const { onboardingTaxonomy, storeOnboarding } = OnboardingRepository();

  const handleFetchTaxonomies = () => {
    onboardingTaxonomy?.().then((res) => {
      if (res.isSuccess) {
        setOnboardingTaxonomies(res.data?.data);
      }
    });
  };

  const handleStoreOnboarding = (data: StoreOnboardingDto) => {
    storeOnboarding?.(data).then((res) => {
      if (res.isSuccess) {
        window.location.href = paths.dashboard.index;
      }
    });
  };

  useEffect(() => {
    //fetchOnboarding("yanguyt");
    handleFetchTaxonomies();
  }, []);

  return (
    <OnboardingContext.Provider
      value={{ onboardingTaxonomies, onSaveOnboarding: handleStoreOnboarding }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingProvider;
