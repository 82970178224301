import type { FC } from "react";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import { IconButton, SvgIcon, Tooltip } from "@mui/material";
import { useDialog } from "src/hooks/use-dialog";
import { SearchDialog } from "./search-dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SearchButton: FC = () => {
  const dialog = useDialog();

  return (
    <>
      <Tooltip title="Search">
        <IconButton onClick={dialog.handleOpen}>
          <FontAwesomeIcon icon={["far", "magnifying-glass"]} size="xs" />
        </IconButton>
      </Tooltip>
      <SearchDialog onClose={dialog.handleClose} open={dialog.open} />
    </>
  );
};
