import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { CSSProperties } from "react";
import SatisficationImage from "/assets/certificates/satisfaction.png";
interface CheckoutSummaryProps {
  sx?: CSSProperties;
}

const CheckoutSummary = ({ sx }: CheckoutSummaryProps) => {
  const { palette } = useTheme();
  return (
    <Box sx={{ ...sx }}>
      <Box
        sx={{
          backgroundColor: palette.background.paper,
          borderRadius: "8px",
          width: "500px",
        }}
      >
        <Box
          sx={{
            padding: "32px",
          }}
        >
          <Typography
            fontWeight={500}
            fontSize={"20px"}
            color={palette.text.primary}
          >
            Summary
          </Typography>
          <Box
            marginY={"24px"}
            sx={{
              height: "1px",
              width: "100%",
              backgroundColor: palette.secondary[50],
            }}
          ></Box>
          <Stack
            sx={{
              backgroundColor: palette.primary[50],
              borderRadius: "4px",
              overflow: "hidden",
            }}
            direction={"row"}
          >
            <Box sx={{ width: "100%", padding: "12px 24px" }}>
              <Typography
                fontWeight={600}
                fontSize={"14px"}
                color={palette.primary[500]}
              >
                On-demand
              </Typography>
              <Typography
                fontSize={"20px"}
                fontWeight={600}
                color={palette.text.primary}
              >
                Graphic + Video Design Plan
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "12px",
                marginLeft: "0px",
                backgroundColor: palette.primary[500],
              }}
            >
              <Typography color={"white"} fontSize={"20px"} fontWeight={600}>
                $999
              </Typography>
              <Typography color={"white"} fontSize={"16px"} fontWeight={400}>
                /month
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Box
          sx={{
            height: "1px",
            width: "100%",
            backgroundColor: palette.secondary[50],
          }}
        ></Box>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          padding={"32px"}
        >
          <Box>
            <Typography
              fontSize={"14px"}
              fontWeight={400}
              color={palette.text.secondary}
            >
              Future payment
            </Typography>
            <Typography
              fontSize={"16px"}
              fontWeight={500}
              color={palette.text.primary}
            >
              Due today
            </Typography>
          </Box>
          <Box>
            <Typography
              textAlign={"right"}
              fontSize={"14px"}
              fontWeight={400}
              color={palette.text.secondary}
            >
              $999/month
            </Typography>
            <Typography
              textAlign={"right"}
              fontSize={"16px"}
              fontWeight={500}
              color={palette.text.primary}
            >
              $999/month
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            height: "1px",
            width: "100%",
            backgroundColor: palette.secondary[50],
          }}
        ></Box>
        <Box padding={"32px"}>
          <Typography
            fontWeight={500}
            fontSize={"24px"}
            color={palette.text.primary}
          >
            Features
          </Typography>
          <Box marginTop={"4px"}></Box>
          <Stack
            alignItems={"center"}
            direction={"row"}
            marginTop={"20px"}
            spacing={"12px"}
          >
            <FontAwesomeIcon
              size={"sm"}
              icon={["far", "check"]}
              color={palette.success[500]}
            />
            <Typography
              fontSize={"14px"}
              color={palette.text.primary}
              fontWeight={500}
            >
              On-Demand Design
            </Typography>
          </Stack>
          <Stack
            alignItems={"center"}
            direction={"row"}
            marginTop={"12px"}
            spacing={"12px"}
          >
            <FontAwesomeIcon
              size={"sm"}
              icon={["far", "check"]}
              color={palette.success[500]}
            />
            <Typography
              fontSize={"14px"}
              color={palette.text.primary}
              fontWeight={500}
            >
              Unlimited Revisions{" "}
            </Typography>
          </Stack>
          <Stack
            alignItems={"center"}
            direction={"row"}
            marginTop={"12px"}
            spacing={"12px"}
          >
            <FontAwesomeIcon
              size={"sm"}
              icon={["far", "check"]}
              color={palette.success[500]}
            />
            <Typography
              fontSize={"14px"}
              color={palette.text.primary}
              fontWeight={500}
            >
              Multiple Brands
            </Typography>
          </Stack>
          <Stack
            alignItems={"center"}
            direction={"row"}
            marginTop={"12px"}
            spacing={"12px"}
          >
            <FontAwesomeIcon
              size={"sm"}
              icon={["far", "check"]}
              color={palette.success[500]}
            />
            <Typography
              fontSize={"14px"}
              color={palette.text.primary}
              fontWeight={500}
            >
              2-4 Day Turnaround
            </Typography>
          </Stack>
          <Stack
            alignItems={"center"}
            direction={"row"}
            marginTop={"12px"}
            spacing={"12px"}
          >
            <FontAwesomeIcon
              size={"sm"}
              icon={["far", "check"]}
              color={palette.success[500]}
            />
            <Typography
              fontSize={"14px"}
              color={palette.text.primary}
              fontWeight={500}
            >
              Design Library Access
            </Typography>
          </Stack>
          <Stack
            alignItems={"center"}
            direction={"row"}
            marginTop={"12px"}
            spacing={"12px"}
          >
            <FontAwesomeIcon
              size={"sm"}
              icon={["far", "check"]}
              color={palette.success[500]}
            />
            <Typography
              fontSize={"14px"}
              color={palette.text.primary}
              fontWeight={500}
            >
              Premium Stock Assets
            </Typography>
          </Stack>
          <Stack
            alignItems={"center"}
            direction={"row"}
            marginTop={"12px"}
            spacing={"12px"}
          >
            <FontAwesomeIcon
              size={"sm"}
              icon={["far", "check"]}
              color={palette.success[500]}
            />
            <Typography
              fontSize={"14px"}
              color={palette.text.primary}
              fontWeight={500}
            >
              Source Files Included
            </Typography>
          </Stack>
          <Stack
            alignItems={"center"}
            direction={"row"}
            marginTop={"12px"}
            spacing={"12px"}
          >
            <FontAwesomeIcon
              size={"sm"}
              icon={["far", "check"]}
              color={palette.success[500]}
            />
            <Typography
              fontSize={"14px"}
              color={palette.text.primary}
              fontWeight={500}
            >
              No Contract
            </Typography>
          </Stack>
          <Stack
            alignItems={"center"}
            direction={"row"}
            marginTop={"12px"}
            spacing={"12px"}
          >
            <FontAwesomeIcon
              size={"sm"}
              icon={["far", "check"]}
              color={palette.success[500]}
            />
            <Typography
              fontSize={"14px"}
              color={palette.text.primary}
              fontWeight={500}
            >
              14-Day Guarantee
            </Typography>
          </Stack>
        </Box>
      </Box>
      <Stack
        direction={"row"}
        marginTop={"56px"}
        spacing={"24px"}
        justifyContent={"center"}
      >
        <img
          style={{ objectFit: "contain", width: "133px" }}
          src={"/assets/certificates/satisfaction.png"}
        />
        <img
          style={{ objectFit: "contain", height: "135px" }}
          src={"/assets/certificates/SSL.png"}
        />
      </Stack>
    </Box>
  );
};

export default CheckoutSummary;
