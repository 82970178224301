import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ReactNode } from "react";
import BadgeNLC from "../badgeNLC";

export interface TabProp {
  label: string;
  component: ReactNode;
  key?: string;
  index?: number;
  value?: string;
  icon?: IconProp;
  status?: string;
  visible?: boolean;
  statusColor?:
    | "primary"
    | "secondary"
    | "info"
    | "warning"
    | "success"
    | "error";
}

interface TabItemNLCProps extends TabProp {
  variant?: "transparent" | "text";
  selected?: boolean;
  size?: "sm" | "md";
  onClick?: () => void;
}

interface TabItemColorScheme {
  bgSelected: string;
  textColorNormal: string;
  textColorSelected: string;
  borderSelected: string;
  underBorderSelected: string;
}

const TabItemNLC = ({
  label,
  icon,
  status,
  statusColor,
  selected = false,
  variant = "text",
  size = "sm",
  onClick,
}: TabItemNLCProps) => {
  const { palette } = useTheme();

  const getColor = (): TabItemColorScheme => {
    switch (palette.mode) {
      case "dark":
        return {
          bgSelected: palette.primary.main,
          textColorNormal: palette.text.primary,
          textColorSelected: palette.text.primary,
          borderSelected: palette.primary.main,
          underBorderSelected: palette.primary.main,
        };
      default:
        return {
          bgSelected: palette.primary[50],
          textColorNormal: palette.text.primary,
          textColorSelected: palette.primary[400],
          borderSelected: palette.primary[100],
          underBorderSelected: palette.primary.main,
        };
    }
  };

  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor:
          selected && variant == "transparent"
            ? getColor().bgSelected
            : "transparent",
        padding: variant == "transparent" ? "3px 8px" : "10px 8px",
        borderRadius: variant == "transparent" ? "8px" : "0px",
        cursor: "pointer",
        border:
          selected && variant == "transparent"
            ? `2px solid ${getColor().borderSelected}`
            : "",
        borderBottom:
          selected && variant == "text"
            ? `2px solid ${getColor().underBorderSelected}`
            : "",
      }}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={"6px"}>
        {icon && (
          <FontAwesomeIcon
            color={
              selected
                ? palette[statusColor ?? "primary"].main
                : palette.text.secondary
            }
            icon={icon}
          />
        )}
        <Typography
          fontSize={size == "sm" ? "12px" : "14px"}
          fontWeight={500}
          color={
            selected ? getColor().textColorSelected : getColor().textColorNormal
          }
        >
          {label}
        </Typography>
        {status && <BadgeNLC text={status} color={statusColor} />}
      </Stack>
    </Box>
  );
};

export default TabItemNLC;
