import {
  Button,
  Stack,
  Typography,
  styled,
  useTheme,
  Box,
} from "@mui/material";
import { CSSProperties, SyntheticEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ButtonNLCProps {
  text?: string;
  size?: "sm" | "md" | "lg";
  color?:
    | "primary"
    | "grey"
    | "info"
    | "warning"
    | "success"
    | "error"
    | "secondary";
  variant?: "fill" | "outlined" | "transparent" | "text" | "noPadding";

  leftIcon?: IconProp;
  rightIcon?: IconProp;
  disabled?: boolean;
  onClick?: () => void;
  hasQuantity?: boolean;
  quantity?: number;
  rounded?: boolean;
  fullWidth?: boolean;
  style?: CSSProperties;
  pulsatingIcon?: boolean;
  pulsatingColor?:
    | "primary"
    | "grey"
    | "info"
    | "warning"
    | "success"
    | "error"
    | "secondary";
  spin?: boolean;
}

interface ButtonColorScheme {
  borderNormal: string;
  borderHover: string;
  borderDisabled: string;
  borderActive: string;
  fontColorNormal: string;
  iconColor: string;
  iconColorDisabled: string;
  bgNormal: string;
  bgHover: string;
  bgDisabled: string;
  bgActive: string;
  outlineBgHover: string;
  outlineBgDisabled: string;
  transparentBgHover: string;
  transparentBgDisabled: string;
  transparentBgActive: string;
  transparentBgNormal: string;
}

interface SizeButtonScheme {
  fontSize: string;
  iconSize: string;
  px: string;
  py: string;
  minHeight: string;
  iconBoxSize: string;
}

const Btn = styled(Button)``;

const ButtonNLC = ({
  text = "teste",
  color = "primary",
  size,
  variant = "fill",
  leftIcon,
  rightIcon,
  disabled = false,
  onClick,
  rounded = false,
  fullWidth,
  pulsatingIcon = false,
  pulsatingColor = "primary",
  style = {},
  spin = false,
}: ButtonNLCProps) => {
  const theme = useTheme();

  const getColor = (): ButtonColorScheme => {
    switch (theme.palette.mode) {
      case "dark":
        return {
          bgNormal: theme.palette[color][400],
          bgHover: theme.palette[color][600],
          bgDisabled: theme.palette[color][100],
          bgActive: theme.palette[color][400],
          borderNormal: theme.palette[color][500],
          borderHover: theme.palette[color][600],
          borderDisabled: theme.palette[color][100],
          borderActive: theme.palette[color][50],
          fontColorNormal: theme.palette[color][50],
          iconColor: theme.palette[color][50],
          iconColorDisabled: theme.palette[color][50],
          outlineBgDisabled: theme.palette[color][50],
          outlineBgHover: theme.palette[color][100],
          transparentBgActive: theme.palette[color][50],
          transparentBgHover: theme.palette[color][50],
          transparentBgDisabled: theme.palette[color][50],
          transparentBgNormal: theme.palette[color][50],
        };
      default:
        return {
          bgNormal: theme.palette[color][400],
          bgHover: theme.palette[color][600],
          bgDisabled: theme.palette[color][100],
          bgActive: theme.palette[color][400],
          borderNormal: theme.palette[color][500],
          borderHover: theme.palette[color][600],
          borderDisabled: theme.palette[color][100],
          borderActive: theme.palette[color][50],
          fontColorNormal: "white",
          iconColor: theme.palette[color][500],
          iconColorDisabled: theme.palette[color][500],
          outlineBgDisabled: theme.palette[color][50],
          outlineBgHover: theme.palette[color][50],
          transparentBgActive: theme.palette[color][50],
          transparentBgHover: theme.palette[color][100],
          transparentBgDisabled: theme.palette[color][50],
          transparentBgNormal: theme.palette[color][50],
        };
    }
  };

  const getSizes = (): SizeButtonScheme => {
    switch (size) {
      case "md":
        return {
          fontSize: "14px",
          iconSize: "16px",
          px: "20px",
          py: "8px",
          minHeight: "40px",
          iconBoxSize: "40px",
        };
      case "lg":
        return {
          fontSize: "15px",
          iconSize: "20px",
          px: "24px",
          py: "11px",
          minHeight: "40px",
          iconBoxSize: "50px",
        };
      default:
        return {
          fontSize: "13px",
          iconSize: "12px",
          px: "16px",
          py: "6px",
          minHeight: "32px",
          iconBoxSize: "32px",
        };
    }
  };

  const getCSSByVariant = (): any => {
    switch (variant) {
      case "outlined":
        return {
          border: `1px solid ${getColor().borderNormal}`,
          color: getColor().borderNormal,
          "&:hover": {
            backgroundColor: getColor().outlineBgHover,
            color: getColor().borderHover,
          } as CSSProperties,
          "&:disabled": {
            backgroundColor: getColor().outlineBgHover,
            color: getColor().borderDisabled,
            border: `1px solid ${getColor().borderDisabled}`,
          } as CSSProperties,
          "&:active": {
            backgroundColor: getColor().outlineBgHover,
            color: getColor().outlineBgHover,
            border: `1px solid ${getColor().outlineBgHover}`,
          } as CSSProperties,
        };
      case "transparent":
        return {
          backgroundColor: getColor().transparentBgNormal,
          color: getColor().borderNormal,
          "&:hover": {
            backgroundColor: getColor().transparentBgHover,
            color: getColor().borderHover,
          } as CSSProperties,
          "&:disabled": {
            backgroundColor: getColor().transparentBgDisabled,
            color: getColor().borderDisabled,
          } as CSSProperties,
          "&:active": {
            backgroundColor: getColor().transparentBgActive,
            color: getColor().borderHover,
            border: getColor().borderActive,
          } as CSSProperties,
        };
      case "text":
        return {
          backgroundColor: "white",
          color: getColor().borderNormal,
          "&:hover": {
            color: getColor().borderHover,
          } as CSSProperties,
          "&:disabled": {
            color: getColor().borderDisabled,
            backgroundColor: "none !important",
          } as CSSProperties,
          "&:active": {
            color: getColor().borderActive,
            border: getColor().transparentBgNormal,
          } as CSSProperties,
        };
      case "noPadding":
        return {
          backgroundColor: "transparent",
          color: getColor().borderNormal,
          "&:hover": {
            color: getColor().borderHover,
            backgroundColor: "transparent ",
          } as CSSProperties,
          "&:disabled": {
            color: getColor().borderDisabled,
          } as CSSProperties,
          "&:active": {
            color: getColor().borderActive,
          } as CSSProperties,
        };
      default:
        return {
          backgroundColor: getColor().bgNormal,
          color: getColor().fontColorNormal,
          "&:hover": {
            backgroundColor: getColor().bgHover,
            color: getColor().fontColorNormal,
          } as CSSProperties,
          "&:disabled": {
            backgroundColor: getColor().bgDisabled,
            color: getColor().fontColorNormal,
          } as CSSProperties,
          "&:active": {
            backgroundColor: getColor().bgActive,
            color: getColor().fontColorNormal,
            border: getColor().borderActive,
          } as CSSProperties,
        };
    }
  };

  return (
    <Btn
      onClick={(e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (onClick) {
          onClick();
        }
      }}
      disabled={disabled}
      style={{
        border: "none",
        minWidth: fullWidth ? "100%" : "0px",
        borderRadius: rounded ? "100%" : "8px",
        minHeight: getSizes().minHeight,
        backgroundColor: "transparent",
        cursor: disabled ? "default" : "pointer",
        ...getCSSByVariant(),
        "&:hover": {
          padding:
            variant != "noPadding"
              ? `${getSizes().py} ${getSizes().px}`
              : "8px",
        },
        width:
          text == "" && leftIcon
            ? getSizes().iconBoxSize
            : rounded
            ? getSizes().minHeight
            : "auto",
        height:
          text == "" && leftIcon
            ? getSizes().iconBoxSize
            : rounded
            ? getSizes().minHeight
            : "auto",
        padding:
          text == "" && leftIcon
            ? "0px"
            : variant != "noPadding"
            ? `${getSizes().py} ${getSizes().px}`
            : "8px",
        ...style,
      }}
    >
      <Stack direction="row" spacing={"8px"} alignItems="center">
        {leftIcon && pulsatingIcon && (
          <Box
            sx={{
              width: "32px",
              height: "32px",
              backgroundColor: theme.palette[pulsatingColor][200],
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "8px solid " + theme.palette[pulsatingColor][50],
              boxSizing: "border-box",
            }}
          >
            <FontAwesomeIcon
              style={{
                marginTop: size == "sm" ? "0px" : "2px",
                width: getSizes().iconSize,
                height: getSizes().iconSize,
                backgroundColor: theme.palette[pulsatingColor][100],
                borderRadius: "50%",
              }}
              color={theme.palette[pulsatingColor][500]}
              icon={leftIcon}
            />
          </Box>
        )}
        {leftIcon && !pulsatingIcon && (
          <FontAwesomeIcon
            style={{
              marginTop: size == "sm" ? "0px" : "2px",
              width: getSizes().iconSize,
              height: getSizes().iconSize,
            }}
            icon={leftIcon}
          />
        )}
        {text != "" && (
          <Typography
            sx={{
              fontSize: getSizes().fontSize,
            }}
            fontWeight={600}
          >
            {text}
          </Typography>
        )}

        {rightIcon && (
          <FontAwesomeIcon
            style={{
              marginTop: "2px",
              width: getSizes().iconSize,
              height: getSizes().iconSize,
            }}
            icon={rightIcon}
            spin={spin}
          />
        )}
      </Stack>
    </Btn>
  );
};

export default ButtonNLC;
