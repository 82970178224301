import { createContext } from "react";
import { Profile } from "../interfaces/profile";
import { Taxonomies } from "../interfaces/taxonomies";
import { Notification } from "../interfaces/notification";
import Permissions from "../interfaces/permissions";
import { AxiosError } from "axios";

export interface GlobalContextInterface {
  isAuthenticated: boolean;
  profile?: Profile;
  isInitialized: boolean;
  taxonomies?: Taxonomies;
  handleImpersonate?: (username: string) => void;
  handleStopImpersonate?: () => void;
  impersonated: boolean;
  notifications: Notification[];
  unread: number;
  handleMarkAllAsRead?: () => void;
  handleRemoveOne?: (notificationId: string) => void;
  permissions?: Permissions[];
  allowedTo?: (action: string) => boolean;
  handleCatchAxiosError?: (error: AxiosError) => any;
}

const GlobalContext = createContext<GlobalContextInterface>({
  isAuthenticated: false,
  isInitialized: false,
  impersonated: false,
  notifications: [],
  unread: 0,
});

export default GlobalContext;
