import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, Stack, useTheme, Grid } from "@mui/material";
import { CSSProperties } from "react";
import { Link } from "react-router-dom";
import ButtonNLC from "src/modules/globals/components/buttonNLC";
import TextFieldNLC from "src/modules/globals/components/textFieldNLC/Index";

interface CheckoutBasicInfoProps {
  sx?: CSSProperties;
}

const CheckoutBasicInfo = ({ sx }: CheckoutBasicInfoProps) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: palette.background.paper,
        borderRadius: "8px",
        width: "500px",
        padding: "32px",
        ...sx,
      }}
    >
      <Typography
        fontWeight={500}
        fontSize={"20px"}
        color={palette.text.primary}
      >
        Personal Information
      </Typography>
      <Box
        marginY={"24px"}
        sx={{
          height: "1px",
          width: "100%",
          backgroundColor: palette.secondary[50],
        }}
      ></Box>
      <Grid container spacing={"24px"}>
        <Grid item xs={6}>
          <TextFieldNLC label="First name" />
        </Grid>
        <Grid item xs={6}>
          <TextFieldNLC label="Last name" />
        </Grid>
        <Grid item xs={12}>
          <TextFieldNLC label="Email address" />
        </Grid>
        <Grid item xs={6}>
          <TextFieldNLC label="Phone number" />
        </Grid>
        <Grid item xs={6}>
          <TextFieldNLC label="Zip code" />
        </Grid>
        <Grid item xs={12}>
          <TextFieldNLC label="Company name" />
        </Grid>
      </Grid>

      <Typography
        marginTop={"24px"}
        fontWeight={500}
        fontSize={"20px"}
        color={palette.text.primary}
      >
        Have a discount code?
      </Typography>
      <Box
        marginY={"24px"}
        sx={{
          height: "1px",
          width: "100%",
          backgroundColor: palette.secondary[50],
        }}
      ></Box>
      <Stack direction={"row"} spacing={"24px"} alignItems={"center"}>
        <TextFieldNLC sx={{ flex: 1 }} label="Gift card or discount code" />
        <ButtonNLC style={{ height: "52px" }} text="Apply" size="md" />
      </Stack>
      <Typography
        marginTop={"24px"}
        fontWeight={500}
        fontSize={"20px"}
        color={palette.text.primary}
      >
        Payment information
      </Typography>
      <Box
        marginY={"24px"}
        sx={{
          height: "1px",
          width: "100%",
          backgroundColor: palette.secondary[50],
        }}
      ></Box>
      <Grid container spacing={"24px"}>
        <Grid item xs={12}>
          <TextFieldNLC label="Card number" />
        </Grid>
        <Grid item xs={12}>
          <TextFieldNLC label="Name on card" />
        </Grid>
        <Grid item xs={6}>
          <TextFieldNLC label="Expiry date (mm/yy)" />
        </Grid>
        <Grid item xs={6}>
          <TextFieldNLC label="CVC" />
        </Grid>
      </Grid>
      <Typography fontSize={"12px"} marginTop={"24px"} textAlign={"center"}>
        By completing this purchase, you agree to our{" "}
        <Link
          style={{ textDecoration: "none", color: palette.info[500] }}
          to={"/terms"}
        >
          Terms of Service
        </Link>{" "}
        and acknowledge you have read our{" "}
        <Link
          style={{ textDecoration: "none", color: palette.info[500] }}
          to={"/privacy"}
        >
          Privacy Policy.
        </Link>
      </Typography>
      <Stack
        marginY={"24px"}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <ButtonNLC size="lg" text="Complete purchase" />
      </Stack>
      <Box
        padding={"12px"}
        sx={{ backgroundColor: palette.secondary[50], borderRadius: "8px" }}
      >
        <Typography
          textAlign={"center"}
          fontSize={"12px"}
          color={palette.text.secondary}
        >
          Please note that using a VPN could cause your payment to fail since
          many shared IPs are considered high risk. To solve this issue, simply
          use a normal IP.
        </Typography>
      </Box>
    </Box>
  );
};

export default CheckoutBasicInfo;
