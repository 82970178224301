import { Avatar, Box, Stack, Tooltip } from "@mui/material";
import ButtonNLC from "../buttonNLC";
import Dropzone, { DropzoneRef } from "react-dropzone";
import React, { useRef } from "react";

interface AvatarNLCProps {
  size?: "xs" | "sm" | "md" | "lg" | "xl" | string;
  src?: string;
  initials?: string;
  alt?: string;
  tooltipTitle?: string;
  onDropAvatarAsset?: (file: File) => void;
  onDeleteAvatar?: () => void;
}

const AvatarNLC = ({
  size = "md",
  src,
  initials,
  alt,
  tooltipTitle = "",
  onDropAvatarAsset,
  onDeleteAvatar,
}: AvatarNLCProps) => {
  const dropzoneRef = useRef<DropzoneRef>(null);
  const openDropzone = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };
  const getAvatarSize = () => {
    switch (size) {
      case "xs":
        return "24px";
      case "sm":
        return "32px";
      case "md":
        return "40px";
      case "lg":
        return "48px";
      case "xl":
        return "56px";
      case "xxl":
        return "160px";
      default:
        return size;
    }
  };

  return (
    <Tooltip title={tooltipTitle}>
      <>
        {onDropAvatarAsset && (
          <Stack
            sx={{
              width: getAvatarSize(),
              height: getAvatarSize(),
              borderRadius: "50%",
              transition: "opacity 0.3s ease-in-out",
              position: "absolute",
              display: "flex",
              zIndex: 1,
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                backgroundColor: "rgba(0,0,0,0.5)",
              },
              "&:hover .actions": {
                position: "absolute",
                display: "flex",
              },
            }}
          >
            <Stack
              direction={"row"}
              textAlign={"center"}
              spacing={1}
              className={"actions"}
              display={"none"}
            >
              <Dropzone
                maxFiles={1}
                multiple={false}
                ref={dropzoneRef}
                onDrop={(files) => onDropAvatarAsset(files[0])}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box {...getRootProps()}>
                    <ButtonNLC
                      onClick={openDropzone}
                      variant="fill"
                      text=""
                      leftIcon={["far", "image"]}
                      size="md"
                    />
                    <input {...getInputProps()} />
                  </Box>
                )}
              </Dropzone>
              {onDeleteAvatar && (
                <ButtonNLC
                  onClick={onDeleteAvatar}
                  color="error"
                  text=""
                  leftIcon={["far", "trash-can"]}
                  size="md"
                />
              )}
            </Stack>
          </Stack>
        )}
        <Avatar
          alt={alt}
          sx={{
            width: getAvatarSize(),
            height: getAvatarSize(),
            position: "relative",
            zIndex: onDropAvatarAsset ? 0 : 1,
          }}
          src={src}
        >
          {initials?.slice(0, 2)}
        </Avatar>
      </>
    </Tooltip>
  );
};

export default AvatarNLC;
