import { lazy, Suspense } from "react";
import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";
import { Layout as DashboardLayout } from "src/layouts/dashboard";

const ProductTemplate = lazy(
  () => import("../../products/templates/productTemplate"),
);

const SettingsTemplate = lazy(
  () => import("../../settings/template/settingsTemplate"),
);

const RequestsTemplate = lazy(
  () => import("src/modules/request/templates/requestTemplate"),
);

const NotificationTemplate = lazy(
  () => import("../../notifications/templates/notificationsTemplate"),
);

const DepartmentsTemplate = lazy(
  () => import("../../departments/templates/departmentTemplate"),
);

const IndexPage = lazy(
  () => import("src/modules/dashboard/templates/dashboardTemplate"),
);

// Help Center
const HelpCenterTemplate = lazy(
  () => import("../../helpCenter/templates/helpCenterTemplate"),
);

// Organizations
const OrganizationPage = lazy(
  () => import("src/pages/dashboard/organizations"),
);

// Users
const UserTemplate = lazy(
  () => import("../../user/templates/userTemplate/index"),
);

// Teams
const TeamsPage = lazy(() => import("src/pages/dashboard/teams"));

// Accounts
const AccountsPage = lazy(
  () => import("src/modules/accounts/templates/accountsTemplate"),
);

// Brands
const BrandsPage = lazy(
  () => import("src/modules/brands/templates/brandsTemplate"),
);

const BillingPage = lazy(
  () => import("../../billing/templates/billingTemplate/index"),
);

const CreateRequestPage = lazy(
  () => import("src/modules/request/components/createNewRequestPage/index"),
);

export const dashboardRoutes: RouteObject[] = [
  {
    path: "dashboard",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: "notifications",
        children: [
          {
            index: true,
            element: <NotificationTemplate />,
          },
        ],
      },
      {
        path: "help-center",
        children: [
          {
            index: true,
            element: <HelpCenterTemplate />,
          },
        ],
      },
      {
        path: "users",
        children: [
          {
            index: true,
            element: <UserTemplate />,
          },
        ],
      },
      {
        path: "organizations",
        children: [
          {
            index: true,
            element: <OrganizationPage />,
          },
        ],
      },
      {
        path: "departments",
        children: [
          {
            index: true,
            element: <DepartmentsTemplate />,
          },
        ],
      },
      {
        path: "teams",
        children: [
          {
            index: true,
            element: <TeamsPage />,
          },
        ],
      },
      {
        path: "accounts",
        children: [
          {
            index: true,
            element: <AccountsPage />,
          },
        ],
      },
      {
        path: "brands",
        children: [
          {
            index: true,
            element: <BrandsPage />,
          },
        ],
      },
      {
        path: "settings",
        children: [
          {
            index: true,
            element: <SettingsTemplate />,
          },
        ],
      },
      {
        path: "products",
        children: [
          {
            index: true,
            element: <ProductTemplate />,
          },
        ],
      },
      {
        path: "requests",
        children: [
          {
            index: true,
            element: <RequestsTemplate />,
          },
          {
            path: "create",
            element: <CreateRequestPage />,
          },
        ],
      },
    ],
  },
  {
    path: "user",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: "settings",
        children: [
          {
            index: true,
            element: <BillingPage />,
          },
        ],
      },
    ],
  },
];
