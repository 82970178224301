import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Stack,
  SxProps,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { SearchItem } from "../textfieldWithSuggestionsNLC";
import { generateRandomUUID } from "../../utils/uuid";
import TagNLC from "../tagNLC";
import auroraTheme from "src/aurora/themes";

interface TextFieldNLCProps {
  placeholder?: string;
  label?: string;
  value?: string;
  maxLength?: number | undefined;
  onChange?: (text: string) => void;
  position?: "left" | "right";
  icon?: ReactNode;
  additionalText?: string;
  variant?: "text+icon" | "icon+text";
  disabled?: boolean;
  error?: string;
  hint?: string;
  selectable?: boolean;
  onClick?: () => void;
  onFocusChange?: (focus: boolean) => void;
  focused?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  sx?: SxProps;
  readonly?: boolean;
  selectIcon?: boolean;
  type?: "email" | "password" | "text" | "number";
  multipleSelection?: boolean;
  selectedValues?: SearchItem[];
  onPressEnter?: () => void;
  onTagClick?: (item: SearchItem) => void;
  isColor?: boolean;
  color?: "primary" | "secondary" | "info" | "warning" | "success" | "error";
  isMultiline?: boolean;
  isRefactored?: boolean;
}

const InputNLC = styled("input")(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  fontFamily: theme.typography.fontFamily,
  border: "none",
  color: theme.palette.text.primary,
  "&::placeholder": {
    color: theme.palette.text.secondary,
  },
  "&:focus": {
    outline: theme.palette.primary.main,
  },
  width: "100%",
}));

const TextAreaNLC = styled("textarea")(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 400,
  padding: "0px",
  fontFamily: theme.typography.fontFamily,
  lineHeight: "24px",
  border: "none",
  color: theme.palette.text.primary,
  "&::placeholder": {
    color: theme.palette.text.secondary,
  },
  "&:focus": {
    outline: theme.palette.primary.main,
  },
  flex: 1,
  minWidth: "50px",
  backgroundColor: "white",
  maxWidth: "max-content",
  resize: "none",
}));

const TextFieldNLC = ({
  sx,
  placeholder,
  label = "",
  value = "",
  maxLength,
  onChange,
  icon,
  position = "left",
  additionalText,
  variant = "text+icon",
  disabled = false,
  error = "",
  hint = "",
  selectable = false,
  onClick,
  onFocusChange,
  focused,
  onFocus,
  readonly,
  type = "text",
  selectIcon = false,
  onPressEnter,
  multipleSelection = false,
  selectedValues,
  onTagClick,
  isColor = false,
  color = "primary",
  isMultiline = false,
  isRefactored = false,
}: TextFieldNLCProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocusedInternal, setIsFocused] = useState(false);

  useEffect(() => {
    inputRef.current?.addEventListener("focus", (e) => {
      inputRef.current?.focus();
      inputRef.current?.setSelectionRange(
        inputRef.current?.value.length,
        inputRef.current?.value.length,
      );
      if (onFocusChange != null) onFocusChange?.(true);
      else setIsFocused(true);
    });
    inputRef.current?.addEventListener("focusout", (e) => {
      if (onFocusChange != null) onFocusChange?.(false);
      else setIsFocused(false);
    });
    return () => {
      inputRef.current?.removeEventListener("focus", () => {});
      // eslint-disable-next-line react-hooks/exhaustive-deps
      inputRef.current?.removeEventListener("focusout", () => {});
    };
  }, []);

  const isFocused = readonly
    ? false
    : focused != null
    ? focused
    : isFocusedInternal;

  useEffect(() => {
    if (isFocused) {
      inputRef.current?.focus();
    }
  }, [isFocused]);

  const { palette } = useTheme();
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onPressEnter?.();
    }
  };

  const generateRows = useCallback(() => {
    const maxCharsPerLine = 45;
    const maxLinesPerInput = 6;

    const calculateDynamicLines = () => {
      const lines = value.split("\n");
      let totalLines = lines.length;

      lines.forEach((line) => {
        let characters = line.length;
        while (characters > maxCharsPerLine) {
          totalLines++;
          characters -= maxCharsPerLine;
        }
      });

      return Math.min(totalLines, maxLinesPerInput);
    };

    return calculateDynamicLines();
  }, [value]);

  return (
    <Box sx={{ ...sx }}>
      <Stack direction={"row"}>
        {(additionalText || icon) && position == "left" ? (
          <Stack
            sx={{
              bgcolor: palette.secondary[50],
              padding: "12px 10px",
              borderRadius: "8px 0px 0px 8px",
              border: `1px solid ${palette.text.disabled}`,
              borderRightStyle: "none",
            }}
            alignItems={"center"}
            justifyContent={"center"}
            spacing={"2.5px"}
            direction={variant == "text+icon" ? "row" : "row-reverse"}
          >
            {additionalText ? (
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: palette.text.secondary,
                }}
              >
                {additionalText}
              </Typography>
            ) : null}
            {icon ? (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                width={"20px"}
              >
                {icon}
              </Stack>
            ) : null}
          </Stack>
        ) : null}

        <Box
          onClick={() => (selectable ? onClick?.() : inputRef.current?.focus())}
          sx={{
            width: "100%",
            border: `1px solid ${
              isRefactored
                ? auroraTheme.mixins.components.textField.borderColor
                : error && !isFocused
                ? palette.error.main
                : isFocused
                ? palette.primary.main
                : palette.text.disabled
            }`,
            backgroundColor: disabled ? palette.secondary[50] : "transparent",
            borderRadius:
              icon || additionalText
                ? position == "left"
                  ? "0 8px 8px 0"
                  : "8px 0px 0px 8px"
                : "8px",
            overflow: "hidden",
            flex: 1,
            ...sx,
          }}
        >
          {label ? (
            <Box
              sx={{
                position: "relative",
                display:
                  isFocused ||
                  value != "" ||
                  (multipleSelection && selectedValues && selectedValues.length)
                    ? "block"
                    : multipleSelection &&
                      selectedValues &&
                      selectedValues.length
                    ? "none"
                    : "block",
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  position: "absolute",
                  left: "14px",
                  fontSize:
                    value != "" ||
                    isFocused ||
                    (multipleSelection &&
                      selectedValues &&
                      selectedValues.length)
                      ? "11px"
                      : "14px",
                  color: isFocused
                    ? palette.primary.main
                    : palette.text.disabled,
                  transform:
                    value != "" ||
                    isFocused ||
                    (multipleSelection &&
                      selectedValues &&
                      selectedValues.length)
                      ? "translateY(25%)"
                      : "translateY(14px)",
                }}
              >
                {value != "" || isFocused ? label : placeholder ?? label}
              </Typography>
            </Box>
          ) : null}

          <Stack
            className="selectable-area"
            onClick={() => (selectable ? onClick?.() : null)}
            sx={{
              backgroundColor: "white",
              padding:
                placeholder && !label
                  ? "8px 12px"
                  : isFocused && label != ""
                  ? multipleSelection && selectedValues && selectedValues.length
                    ? "14px 14px 6px 14px"
                    : "21px 14px 11px 14px"
                  : multipleSelection && selectedValues && selectedValues.length
                  ? "14px 14px 6px 14px"
                  : "21px 14px 11px 14px",

              ...sx,
            }}
            alignItems={"center"}
            direction={"row"}
            flexWrap={"wrap"}
            rowGap={"8px"}
            columnGap={"8px"}
          >
            {multipleSelection && selectedValues && selectedValues?.length > 0
              ? selectedValues.map((item, _) => {
                  return (
                    <TagNLC
                      color={color}
                      isColor={isColor}
                      onClick={() => onTagClick?.(item)}
                      key={generateRandomUUID()}
                      size="sm"
                      text={item.title}
                      isRefactored={isRefactored}
                    />
                  );
                })
              : null}

            {isMultiline ? (
              <TextAreaNLC
                placeholder={placeholder}
                rows={generateRows()}
                cols={46}
                maxLength={maxLength}
                disabled={selectable || disabled || readonly}
                onClick={() => {
                  selectable ? onClick?.() : setIsFocused(true);
                }}
                onChange={(e) => {
                  onChange?.(e.target.value);
                }}
                value={value}
                sx={{
                  cursor: selectable ? "pointer" : "initial",
                  "&:disabled": {
                    backgroundColor:
                      selectable && !disabled ? "transparent" : "",
                  },
                }}
              />
            ) : (
              <InputNLC
                placeholder={placeholder}
                onKeyDown={handleKeyDown}
                type={type}
                maxLength={maxLength}
                onFocus={onFocus}
                disabled={selectable || disabled || readonly}
                sx={{
                  cursor: selectable ? "pointer" : "initial",
                  flex: 1,
                  minWidth: "50px",
                  backgroundColor: "white",
                  "&:disabled": {
                    backgroundColor:
                      selectable && !disabled ? "transparent" : "",
                  },
                  ...sx,
                }}
                onClick={() => {
                  selectable ? onClick?.() : setIsFocused(true);
                }}
                onChange={(e) => onChange?.(e.target.value)}
                value={value}
                ref={inputRef}
              />
            )}
          </Stack>

          {selectable || selectIcon ? (
            <Box
              onClick={() => (selectable ? onClick?.() : null)}
              sx={{ position: "relative" }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "-36px",
                  right: "4px",
                  height: "20px",
                  width: "20px",
                }}
              >
                <FontAwesomeIcon
                  color={palette.text.secondary}
                  icon={["fas", "chevron-down"]}
                />
              </Box>
            </Box>
          ) : null}
        </Box>

        {(additionalText || icon) && position == "right" ? (
          <Stack
            sx={{
              bgcolor: palette.secondary[50],
              padding: "12px 10px",
              borderRadius: "0 8px 8px 0",
              border: `1px solid ${palette.text.disabled}`,
              borderLeftStyle: "none",
            }}
            alignItems={"center"}
            justifyContent={"center"}
            spacing={"2.5px"}
            direction={variant == "text+icon" ? "row" : "row-reverse"}
          >
            {additionalText ? (
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: palette.text.secondary,
                }}
              >
                {additionalText}
              </Typography>
            ) : null}
          </Stack>
        ) : null}
      </Stack>

      <Box position="relative">
        <Typography
          sx={{
            position: "absolute",
            top: 1,
            fontSize: "12px",
            fontWeight: 400,
            color: error ? palette.error.main : palette.text.secondary,
          }}
        >
          {error ? error : hint ?? ""}
        </Typography>
      </Box>
    </Box>
  );
};

export default TextFieldNLC;
