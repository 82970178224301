import { ReactNode, createContext } from "react";

interface AlertContextProps {
  alerts: Alert[];
  createAlert?: (alert: Alert) => void;
}

export interface Alert {
  type: AlertType;
  icon?: ReactNode;
  messages: { title?: string; message: string }[];
  id: string;
  timeout?: number;
  onClose?: () => void;
}

export enum AlertType {
  PRIMARY = "primary",
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

export const AlertContext = createContext<AlertContextProps>({ alerts: [] });
