import { Box, Typography, useTheme } from "@mui/material";
import { CSSProperties, useRef, useState } from "react";
import { useClickAway } from "react-use";

import ReactQuill from "react-quill";

import { removeHtmlTags } from "../../../../utils/remove-htmls-tags";

interface TextareaNLCProps {
  fieldId?: string;
  label?: string;
  value?: string;
  required?: boolean;
  onChange?: (value: string) => void;
  placeholder?: string;
  sx?: CSSProperties;
  heigth?: number;
  customize?: Object;
  backgroundColor?: string;
}

const TextareaNLC = ({
  fieldId = "",
  label = "",
  value = "",
  required = false,
  onChange,
  placeholder,
  sx,
  heigth = 300,
  customize = {},
  backgroundColor = "transparent",
}: TextareaNLCProps) => {
  const textAreaRef = useRef(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  useClickAway(textAreaRef, () => {
    setIsFocused(false);
  });

  const { palette } = useTheme();
  const modules = {
    ...customize,
    clipboard: {
      matchVisual: false,
    },
  };

  const isEmptyText = (): boolean => {
    return value === null || removeHtmlTags(value) === "";
  };

  return (
    <Box
      id={`quill-editor-${fieldId}`}
      ref={textAreaRef}
      sx={{
        position: "relative",
        "& .ql-toolbar": {
          display: "flex",
          justifyContent: "center",
          margin: "12px 12px 0 12px",
          border: `1px solid ${palette.secondary[100]}`,
          borderRadius: "8px",
          position: "absolute",
          width: "calc(100% - 24px)",
          zIndex: 1,
          "& .ql-stroke": {
            stroke: palette.text.secondary,
          },
          "& .ql-fill": {
            fill: palette.text.secondary,
          },
        },
        "& .ql-picker": {
          color: palette.text.secondary,
        },
        "& .ql-container": {
          border: `none`,
          paddingTop: "toolbar" in customize ? "0" : "62px",
        },
        ...sx,
      }}
    >
      <ReactQuill
        bounds={`#quill-editor-${fieldId}`}
        placeholder={placeholder}
        onBlur={() => setIsFocused(false)}
        onFocus={() => {
          setIsFocused(true);
        }}
        modules={modules}
        style={{
          border: `1px solid ${
            isFocused ? palette.primary[500] : palette.secondary[100]
          }`,
          borderRadius: "18px",
          overflow: "hidden",
          height: heigth + "px",
          position: "relative",
          backgroundColor: backgroundColor,
        }}
        value={value}
        onChange={(val) => onChange?.(val)}
      />
      {required && isEmptyText() && (
        <Box position={"relative"}>
          <Typography
            sx={{
              position: "absolute",
              top: 1,
              fontSize: "12px",
              fontWeight: 400,
              color: palette.error.main,
            }}
          >
            The {" " + label + " "} is required
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TextareaNLC;
