import { AuroraFont, AuroraTypography } from "./types";

export const typography = {
  fontFamily: ["Inter", "sans-serif"],
  fontSize: ["12px", "14px", "16px"],
  fontWeight: ["400", "500", "600", "700"],
  lineHeight: ["18px", "20px", "24px"],
};

const xs = {
  regular: {
    fontFamily: typography.fontFamily[0],
    fontSize: typography.fontSize[0],
    fontWeight: typography.fontWeight[0],
    lineHeight: typography.lineHeight[0],
  } satisfies AuroraFont,
  medium: {
    fontFamily: typography.fontFamily[0],
    fontSize: typography.fontSize[0],
    fontWeight: typography.fontWeight[1],
    lineHeight: typography.lineHeight[0],
  } satisfies AuroraFont,
  semiBold: {
    fontFamily: typography.fontFamily[0],
    fontSize: typography.fontSize[0],
    fontWeight: typography.fontWeight[2],
    lineHeight: typography.lineHeight[0],
  } satisfies AuroraFont,
  bold: {
    fontFamily: typography.fontFamily[0],
    fontSize: typography.fontSize[0],
    fontWeight: typography.fontWeight[3],
    lineHeight: typography.lineHeight[0],
  } satisfies AuroraFont,
} satisfies AuroraTypography;

const sm = {
  regular: {
    fontFamily: typography.fontFamily[0],
    fontSize: typography.fontSize[1],
    fontWeight: typography.fontWeight[0],
    lineHeight: typography.lineHeight[1],
  } satisfies AuroraFont,
  medium: {
    fontFamily: typography.fontFamily[0],
    fontSize: typography.fontSize[1],
    fontWeight: typography.fontWeight[1],
    lineHeight: typography.lineHeight[1],
  } satisfies AuroraFont,
  semiBold: {
    fontFamily: typography.fontFamily[0],
    fontSize: typography.fontSize[1],
    fontWeight: typography.fontWeight[2],
    lineHeight: typography.lineHeight[1],
  } satisfies AuroraFont,
  bold: {
    fontFamily: typography.fontFamily[0],
    fontSize: typography.fontSize[1],
    fontWeight: typography.fontWeight[3],
    lineHeight: typography.lineHeight[1],
  } satisfies AuroraFont,
} satisfies AuroraTypography;

type TypographyVariant = "xs" | "sm" | "md" | "lg" | "xl";
