import { Box, Stack, useTheme } from "@mui/material";
import React, { CSSProperties, ReactNode, useState } from "react";
import ButtonNLC from "../buttonNLC";

interface StepperNLCProps {
  steps?: ReactNode[];
  sx?: CSSProperties;
  onCancel?: () => void;
  onSave?: () => void;
  disableOnSaveSteps?: boolean[];
  variant?: "default" | "onboarding";
}

const StepperNLC = ({
  steps = [],
  sx,
  onCancel,
  onSave,
  disableOnSaveSteps,
  variant = "default",
}: StepperNLCProps) => {
  const [actualStep, setActualStep] = useState<number>(0);
  const { palette } = useTheme();

  const getDisableOnSave = (): boolean => {
    if (!disableOnSaveSteps) {
      return false;
    }
    return disableOnSaveSteps[actualStep];
  };
  return (
    <Box sx={{ width: "100%", ...sx }}>
      {steps[actualStep]}
      <Stack
        sx={{ marginTop: "24px" }}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={"8px"}
      >
        {steps.map((step, index) => (
          <Box
            onClick={() => setActualStep(index)}
            key={index}
            sx={{
              cursor: "pointer",
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              backgroundColor:
                actualStep >= index
                  ? palette.primary.main
                  : palette.primary[300],
            }}
          />
        ))}
      </Stack>
      {variant == "onboarding" ? (
        <Stack
          paddingTop={"32px"}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={"12px"}
        >
          {actualStep == 0 ? (
            <ButtonNLC
              size="md"
              fullWidth
              leftIcon={["far", "rocket-launch"]}
              text="Let's start!"
              onClick={() => setActualStep(actualStep + 1)}
            />
          ) : (
            <>
              <ButtonNLC
                size="md"
                leftIcon={["far", "chevron-left"]}
                variant="outlined"
                color="secondary"
                text="Back"
                onClick={() => setActualStep(actualStep - 1)}
              />
              <ButtonNLC
                onClick={
                  actualStep == steps.length - 1
                    ? onSave
                    : () => setActualStep(actualStep + 1)
                }
                size="md"
                text={actualStep == steps.length - 1 ? "Finish" : "Next"}
                rightIcon={["far", "chevron-right"]}
              />
            </>
          )}
        </Stack>
      ) : (
        <Stack
          marginTop={"24px"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          {actualStep != 0 && (
            <ButtonNLC
              style={{ flexGrow: 1, justifyContent: "start" }}
              onClick={() => setActualStep(actualStep - 1)}
              variant="noPadding"
              text="Back"
              size="lg"
              leftIcon={["far", "chevron-left"]}
            />
          )}
          <ButtonNLC
            style={{ flexGrow: 1 }}
            size={"lg"}
            text="Cancel"
            variant="outlined"
            color="secondary"
            onClick={onCancel}
          />
          <Box sx={{ padding: "0 4px" }}></Box>
          <ButtonNLC
            style={{ flexGrow: 1 }}
            size={"lg"}
            text={actualStep == steps.length - 1 ? "Create" : "Next"}
            disabled={getDisableOnSave()}
            color={getDisableOnSave() ? "secondary" : "primary"}
            leftIcon={
              actualStep == steps.length - 1
                ? ["far", "circle-check"]
                : undefined
            }
            rightIcon={
              actualStep == steps.length - 1
                ? undefined
                : ["far", "chevron-right"]
            }
            onClick={
              actualStep == steps.length - 1
                ? onSave
                : () => setActualStep(actualStep + 1)
            }
          />
        </Stack>
      )}
    </Box>
  );
};

export default StepperNLC;
