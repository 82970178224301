/* eslint-disable no-unused-vars */
export enum ApiRoutes {
  IMPERSONATE = "/auth/impersonate",
  SIGN_IN = "/auth/login",
  ORGANIZATIONS = "/organizations",
  ORGANIZATION = "/organization",
  ORGANIZATION_DEPARTMENTS = "/organization/departments",
  TEAMS_BY_ORGANIZATION = "/organization/teams",
  PRODUCTS_BY_ORGANIZATION = "/organization/products",
  ROLES_BY_ORGANIZATION = "/organization/roles",
  ROLE_USER_CAN_CREATE = "/role/roles-user-can-create",
  TEAMS = "/teams",
  TEAM = "/team",
  PRODUCTS = "/products",
  PRODUCT = "/product",
  PRODUCT_FEATURE = "/product/feature",
  PRODUCT_FEATURES = "/product/features",
  DEPARTMENTS = "/departments",
  DEPARTMENT = "/department",
  DEPARTMENT_LEADER = "/department/leader",
  ACCOUNTS = "/accounts",
  ACCOUNT = "/account",
  ACCOUNT_TEAMS = "/account/teams",
  ACCOUNT_TEAM = "/account/team",
  ACCOUNT_ALLOWED_TEAMS = "/account/allowed-teams",
  ACCOUNT_USERS = "/account/users",
  ACCOUNT_BRANDS = "/account/brands",
  ACCOUNT_PREFFERED_DESIGNERS = "/account/preferred-designers",
  ACCOUNT_NON_PREFFERED_DESIGNERS = "/account/non-preferred-designers",
  ACCOUNT_DESIGNER = "/account/designer",
  TAXONOMIES = "/taxonomies",
  TEAM_ACCOUNTS = "/team/accounts",
  TEAM_MEMBERS = "/team/members",
  TEAM_ADD_ACCOUNTS = "/team/add-accounts",
  TEAM_REMOVE_ACCOUNTS = "/team/remove-accounts",
  TEAM_ADD_MEMBERS = "/team/add-members",
  TEAM_REMOVE_MEMBERS = "/team/remove-members",
  BRANDS = "/brands",
  BRAND = "/brand",
  BRAND_ASSET_UPLOAD_URL = "brand/asset/upload-url",
  BRAND_ASSET = "/brand/asset",
  BRAND_ASSETS = "/brand/assets",
  PROFILE = "/user/client-profile",
  PROFILE_ASSET = "/user/avatar",
  PROFILE_ASSETS_URL = "/user/avatar/upload-url",
  USERS = "/users",
  USER = "/user",
  USER_SECURITY = "/user/security-info",
  USER_PROFILE = "/user/self",
  ARTICLES = "/settings/articles",
  ARTICLE = "/settings/article",
  ARTICLE_CHANGE_ORDER = "/settings/article/change-order",
  ARTICLE_CATEGORIES = "/settings/articles-categories",
  ARTICLE_CATEGORY = "/settings/article-category",
  SETTINGS_PROFILE = "/user/settings/profile",
  USER_PERMISSIONS = "/user/settings/permissions",
  SETTINGS_NOTIFICATION = "/user/settings/notification",
  SETTINGS_BILLING = "/user/settings/billing",
  SETTINGS_SECURITY = "/user/settings/security-info",
  SETTINGS_CHANGE_PASSWORD = "/user/settings/change-password",
  SUSPEND_ACCOUNT = "/user/settings/suspend",
  REQUESTS = "/requests",
  REQUEST = "/request",
  REQUEST_REOPEN = "/request/reopen",
  REQUEST_DUPLICATE = "/request/duplicate",
  REQUEST_DESIGN_TYPES = "/request/design-types",
  REQUEST_SETTINGS = "/request/settings",
  REQUEST_BRAND = "/request/brand",
  REQUEST_CHANGE_STATUS = "/request/change-status",
  REQUEST_CHANGE_DUE_DATE = "/request/due-on",
  REQUEST_COMMENTS = "/request/comments",
  REQUEST_COMMENT = "/request/comment",
  REQUEST_COMPLETE = "/request/complete",
  REQUEST_COMMENTS_UPDATES = "/request/comments-and-updates",
  REQUEST_COMMENTS_UPDATES_USERS = "/request/comments-and-updates/users",
  REQUEST_COMMENT_ATT_URL = "/request/comments-and-updates/attachment/upload-url",
  REQUEST_COMMENT_ATT = "/request/comments-and-updates/attachment",
  REQUEST_FEEDBACK = "/request/feedback",
  REQUEST_ASSETS = "/request/assets",
  REQUEST_ASSET = "/request/asset",
  REQUEST_ASSETS_URL = "/request/asset/upload-url",
  FEEDBACK_TOOL_COMMENTS_DOTS = "/feedback-tool/comment-dots",
  FEEDBACK_TOOL_COMMENTS_DOT = "/feedback-tool/comment-dot",
  FEEDBACK_TOOL_COMMENT_DOT_ASSETS = "/feedback-tool/comment-dot/assets",
  FEEDBACK_TOOL_COMMENT_DOT_ASSET = "/feedback-tool/comment-dot/asset",
  FEEDBACK_TOOL_COMMENT_DOT_ATT_URL = "/feedback-tool/comment-dot/asset/upload-url",
  ONBOARDING = "/user/onboarding",
  ONBOARDING_TAXONOMY = "/user/onboarding/taxonomy",
  DASHBOARD = "/dashboard",
  ASSETS = "/assets",
}
