interface LogoProps {
  inverted?: boolean;
}

export const Logo = ({ inverted = true }: LogoProps) => {
  return (
    <img
      style={{ objectFit: "contain", width: "100%", height: "100%" }}
      src={
        inverted
          ? "/assets/logos/newNLCInverted.png"
          : "/assets/logos/newNLC.png"
      }
      alt="NLC"
    />
  );
};
