import type { ComponentsSystem } from "./types";

export const baseColor = "#6C5EFB";

export const errorColors = [];

export const warningColors = [];

export const successColors = [];

export const shadowColors = ["#E5EAF1"];

export const textSecondary = ["#344056"];

export const textDisabled = ["#667086"];

export const components = {
  badge: {
    color: "#067648",
    borderColor: "#ABEFC8",
    backgroundColor: "#ECFDF4",
  },
  drawerAddon: {
    backgroundColor: "#f9fafc",
    divider: "#EAECF2",
  },
  drawerMainInfo: {
    color: "#344056",
  },
  tag: {
    borderColor: "#CED5E0",
    backgroundColor: "#F2F4F8",
    textColor: "#414C63",
    iconColor: "#98a2b5",
  },
  textField: {
    borderColor: "#D0D5DE",
  },
} satisfies ComponentsSystem;
