import { tokens } from "../tokens";

export const es = {
  [tokens.action.copyLink]: "Copiar link",
  [tokens.common.languageChanged]: "Crear una nueva marca",
  [tokens.create.account]: "Crear una nueva cuenta",
  [tokens.create.announcement]: "Crear un nuevo anuncio",
  [tokens.create.category]: "Crear una nueva categoría",
  [tokens.create.coupon]: "Crear un nuevo cupón",
  [tokens.create.course]: "Crear un nuevo artículo",
  [tokens.create.dashboardFeed]: "Crear un enlace de feed",
  [tokens.create.department]: "Crear un nuevo departamento",
  [tokens.create.maintenance]: "Establecer un nuevo mantenimiento",
  [tokens.create.organization]: "Crear una nueva organización",
  [tokens.create.request]: "Crear una nueva solicitud",
  [tokens.create.team]: "Crear un nuevo equipo",
  [tokens.create.user]: "Crear un nuevo usuario",
  [tokens.nav.academy]: "Academia",
  [tokens.nav.account]: "Cuenta",
  [tokens.nav.accounts]: "Cuentas",
  [tokens.nav.all]: "Todos",
  [tokens.nav.analytics]: "Analítica",
  [tokens.nav.announcements]: "Anuncios",
  [tokens.nav.auth]: "Autenticación",
  [tokens.nav.automatedSuggestion]: "Sugerencia automatizada",
  [tokens.nav.blacklist]: "Lista negra",
  [tokens.nav.blog]: "Blog",
  [tokens.nav.brands]: "Marcas",
  [tokens.nav.browse]: "Buscar",
  [tokens.nav.calendar]: "Calendario",
  [tokens.nav.categories]: "Categorías",
  [tokens.nav.chat]: "Chat",
  [tokens.nav.checkout]: "Pago",
  [tokens.nav.concepts]: "Conceptos",
  [tokens.nav.contact]: "Contacto",
  [tokens.nav.coupons]: "Cupones",
  [tokens.nav.course]: "Artículo",
  [tokens.nav.courses]: "Artículos",
  [tokens.nav.create]: "Crear",
  [tokens.nav.crypto]: "Crypto",
  [tokens.nav.dashboard]: "Dashboard",
  [tokens.nav.dashboardFeed]: "Feed de dashboard",
  [tokens.nav.departments]: "Departamentos",
  [tokens.nav.details]: "Detalles",
  [tokens.nav.ecommerce]: "E-Commerce",
  [tokens.nav.edit]: "Editar",
  [tokens.nav.error]: "Error",
  [tokens.nav.feed]: "Fuente social",
  [tokens.nav.fileManager]: "Administrador de Archivos",
  [tokens.nav.finance]: "Finanzas",
  [tokens.nav.fleet]: "Parque",
  [tokens.nav.forgotPassword]: "Recuperar Contraseña",
  [tokens.nav.helpCenter]: "Centro de ayuda",
  [tokens.nav.inactiveRequests]: "Peticiones inactivas",
  [tokens.nav.inspiration]: "Inspiración",
  [tokens.nav.invoiceList]: "Facturas",
  [tokens.nav.jobList]: "Listado de Trabajos",
  [tokens.nav.kanban]: "Kanban",
  [tokens.nav.list]: "Lista",
  [tokens.nav.login]: "Acceso",
  [tokens.nav.logistics]: "Logística",
  [tokens.nav.mail]: "Correo",
  [tokens.nav.maintenance]: "Mantenimiento",
  [tokens.nav.management]: "Gestión",
  [tokens.nav.orderList]: "Pedidos",
  [tokens.nav.organizations]: "Organizaciones",
  [tokens.nav.overview]: "Visión general",
  [tokens.nav.pages]: "Páginas",
  [tokens.nav.postCreate]: "Crear Articulo",
  [tokens.nav.postDetails]: "Detalles del Articulo",
  [tokens.nav.postList]: "Lista de Articulos",
  [tokens.nav.pricing]: "Precios",
  [tokens.nav.productList]: "Productos",
  [tokens.nav.profile]: "Perfil",
  [tokens.nav.register]: "Registrarse",
  [tokens.nav.reports]: "Informes",
  [tokens.nav.requests]: "Solicitudes",
  [tokens.nav.resetPassword]: "Restablecer Contraseña",
  [tokens.nav.save]: "Guardar",
  [tokens.nav.socialMedia]: "Redes Sociales",
  [tokens.nav.teamAssignment]: "Asignación de equipo",
  [tokens.nav.teams]: "Equipos",
  [tokens.nav.tools]: "Herramientas",
  [tokens.nav.users]: "Usuarios",
  [tokens.nav.verifyCode]: "Verificar Código",
};
