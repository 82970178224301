import { Box, useTheme } from "@mui/material";

interface CheckboxNLCProps {
  value: boolean;
  onChange: (value: boolean) => void;
  variant?: "primary" | "secondary";
  disabled?: boolean;
}

const CheckboxNLC = ({ value, onChange }: CheckboxNLCProps) => {
  const { palette } = useTheme();
  return (
    <Box>
      <input
        type="checkbox"
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
      />
    </Box>
  );
};

export default CheckboxNLC;
