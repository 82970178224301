import { RoleTypes } from "src/modules/roles/interfaces/roles";
import useGlobalConfig from "./useGlobalConfig";
import { useRouter } from "src/hooks/use-router";
import { useEffect } from "react";
import { paths } from "src/paths";

interface GuardPageInterface {
  loggedIn?: boolean;
  redirect?: string;
  action?: string;
}

const useGuardPages = ({
  loggedIn = true,
  redirect,
  action,
}: GuardPageInterface) => {
  const { isAuthenticated, isInitialized, profile, permissions } =
    useGlobalConfig();
  const { push } = useRouter();

  useEffect(() => {
    if (loggedIn && !isAuthenticated && isInitialized) {
      push(redirect ? redirect : paths.auth.jwt.login);
    } else if (
      isAuthenticated &&
      isInitialized &&
      profile &&
      action &&
      permissions?.length
    ) {
      if (!permissions?.some((perm) => perm.action === action)) {
        push(redirect ? redirect : paths.dashboard.index);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isInitialized, profile, permissions]);

  return;
};

export default useGuardPages;
