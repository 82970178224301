import type { User } from "src/types/user";
import { createResourceId } from "src/utils/create-resource-id";
import { decode, JWT_EXPIRES_IN, JWT_SECRET, sign } from "src/utils/jwt";
import { wait } from "src/utils/wait";
import { getPublicAPI } from "src/modules/core/config/axios";
import { ApiRoutes } from "src/modules/core/const/ApiRoutes";
import {
  GlobalDefaultResponseErrorInterface,
  GlobalDefaultResponseInterface,
} from "src/modules/globals/interfaces/globalDefaultResponseInterface/globalDefaultResponseInterface";
import { users } from "src/api/auth/data";
import { Profile } from "src/modules/globals/interfaces/profile";

export const STORAGE_KEY: string = "users";

// NOTE: We use sessionStorage since memory storage is lost after page reload.
//  This should be replaced with a server call that returns DB persisted data.

const getPersistedUsers = (): User[] => {
  try {
    const data = sessionStorage.getItem(STORAGE_KEY);

    if (!data) {
      return [];
    }

    return JSON.parse(data) as User[];
  } catch (err) {
    console.error(err);
    return [];
  }
};

const persistUser = (user: User): void => {
  try {
    const users = getPersistedUsers();
    const data = JSON.stringify([...users, user]);
    sessionStorage.setItem(STORAGE_KEY, data);
  } catch (err) {
    console.error(err);
  }
};

type SignInRequest = {
  email: string;
  password: string;
};

interface SignInResponse {
  accessToken: string;
}

type SignUpRequest = {
  email: string;
  name: string;
  password: string;
};

type SignUpResponse = Promise<{
  accessToken: string;
}>;

type MeRequest = {
  accessToken: string;
};

type MeResponse = Promise<User>;

class AuthApi {
  async signIn(
    request: SignInRequest,
  ): Promise<GlobalDefaultResponseInterface<SignInResponse>> {
    const { email, password } = request;
    const axios = getPublicAPI();
    return axios
      .post(ApiRoutes.SIGN_IN, { email, password })
      .then(({ data }): GlobalDefaultResponseInterface<SignInResponse> => {
        try {
          const signResponse: GlobalDefaultResponseInterface<SignInResponse> = {
            data: {
              data: {
                accessToken: data.data.token,
                ...data.data,
              },
            },
            isError: false,
            isSuccess: true,
          };

          return signResponse;
        } catch (err) {
          return {
            error: "Internal server error",
            isError: true,
            isSuccess: false,
          };
        }
      })
      .catch(() => {
        return {
          error: "Please check your email and password",
          isError: true,
          isSuccess: false,
        };
      });
  }

  async signUp(request: SignUpRequest): SignUpResponse {
    const { email, name, password } = request;

    await wait(1000);

    return new Promise((resolve, reject) => {
      try {
        // Merge static users (data file) with persisted users (browser storage)
        const mergedUsers = [...getPersistedUsers()];

        // Check if a user already exists
        let user = mergedUsers.find((user) => user.email === email);

        if (user) {
          reject(new Error("User already exists"));
          return;
        }

        user = {
          id: createResourceId(),
          avatar: undefined,
          email,
          name,
          password,
          plan: "Standard",
        };

        persistUser(user);

        const accessToken = sign({ userId: user.id }, JWT_SECRET, {
          expiresIn: JWT_EXPIRES_IN,
        });

        resolve({ accessToken });
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  async me(
    request: MeRequest,
  ): Promise<GlobalDefaultResponseInterface<Profile>> {
    const axios = getPublicAPI();
    const { accessToken } = request;

    try {
      const data = await axios.get(ApiRoutes.USER_PROFILE, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return {
        data: { data: data.data },
        isError: false,
        isSuccess: true,
      };
    } catch (error) {
      return {
        data: { data: undefined },
        isError: true,
        error: error.message,
        isSuccess: false,
      };
    }
  }
}

export const authApi = new AuthApi();
