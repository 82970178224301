import { Box, Stack, Typography, useTheme } from "@mui/material";
import { SearchItem } from "../textfieldWithSuggestionsNLC";
import { CSSProperties } from "react";

interface RadioNLCProps {
  selected?: string;
  options: SearchItem[];
  direction?: "row" | "column";
  spacing?: number;
  sx?: CSSProperties;
  onSelectItem?: (select: SearchItem) => void;
}

const RadioNLC = ({
  selected,
  options,
  direction,
  spacing = 24,
  sx,
  onSelectItem,
}: RadioNLCProps) => {
  const { palette } = useTheme();
  return (
    <Stack sx={{ ...sx }} spacing={`${spacing}px`} direction={direction}>
      {options.map((opt) => (
        <Stack key={opt.value} spacing={"8px"} direction={"row"}>
          <Stack
            onClick={() => onSelectItem && onSelectItem(opt)}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              cursor: "pointer",
              width: "20px",
              height: "20px",
              borderRadius: "100%",
              backgroundColor:
                opt.value === selected ? palette.primary[500] : "transparent",
              border: `1px solid ${
                opt.value === selected
                  ? palette.primary[600]
                  : palette.grey[200]
              }`,
            }}
          >
            {opt.value === selected && (
              <Box
                sx={{
                  borderRadius: "100%",
                  width: "8px",
                  height: "8px",
                  backgroundColor: "white",
                }}
              ></Box>
            )}
          </Stack>
          <Typography
            sx={{
              cursor: "default",
            }}
            fontSize={"14px"}
            color={palette.text.secondary}
          >
            {opt.title}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default RadioNLC;
