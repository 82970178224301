import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import TextFieldNLC from "src/modules/globals/components/textFieldNLC/Index";
import { StoreOnboardingDto } from "../../interface";

interface OnboardingStep2Props {
  onboardingCreateDto: StoreOnboardingDto;
  setOnboardingCreateDto: (onboardingCreateDto: StoreOnboardingDto) => void;
}

interface industryTagInterface {
  name: string;
  label: string;
  icon: IconProp;
}

const OnboardingStep2 = ({
  onboardingCreateDto,
  setOnboardingCreateDto,
}: OnboardingStep2Props) => {
  const [industrySelected, setIndustrySelected] = useState<string>("");
  const { palette } = useTheme();

  const industries: industryTagInterface[] = [
    {
      name: "Marketing Agency",
      label: "marketing_agency",
      icon: ["far", "megaphone"],
    },
    {
      name: "Design Agency",
      label: "design_agency",
      icon: ["far", "pen-fancy"],
    },
    {
      name: "Ad Manager",
      label: "ad_manager",
      icon: ["far", "chart-simple"],
    },
    {
      name: "Content Creator",
      label: "content_creator",
      icon: ["far", "laptop-mobile"],
    },
    {
      name: "Brick & Mortar",
      label: "brick_mortar",
      icon: ["far", "store"],
    },
    {
      name: "E-Commerce",
      label: "e_commerce",
      icon: ["far", "cart-shopping"],
    },
    {
      name: "Online Service",
      label: "online_service",
      icon: ["far", "globe"],
    },
    {
      name: "Other",
      label: "other",
      icon: ["far", "pen-to-square"],
    },
  ];

  const handleSelectIndustry = (industry: string) => {
    setOnboardingCreateDto({
      ...onboardingCreateDto,
      industry: industry,
      other_description: "",
    });
  };

  const handleWriteOtherIndustry = (other: string) => {
    setOnboardingCreateDto({
      ...onboardingCreateDto,
      other_description: other,
    });
  };

  return (
    <Stack direction={"column"} justifyContent={"center"} alignItems={"center"}>
      <Typography fontSize={"24px"} fontWeight={600} textAlign={"center"}>
        Tell us a bit more about you!
      </Typography>
      <Typography
        fontSize={"14px"}
        color={palette.text.secondary}
        fontWeight={600}
      >
        Choose the industry that most closely matches your business.
      </Typography>
      <Stack
        marginTop={"24px"}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        spacing={"16px"}
      >
        {industries.map((industry) => (
          <Stack
            onClick={() => handleSelectIndustry(industry.name)}
            key={industry.label}
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"90px"}
            height={"138px"}
            sx={{
              border: `1px solid ${palette.grey[100]}`,
              borderRadius: "8px",
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "90px",
                backgroundColor: palette.grey[50],
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                color={palette.primary[500]}
                size="2xl"
                icon={industry.icon}
              />
            </Box>
            <Box
              sx={{
                backgroundColor:
                  onboardingCreateDto.industry == industry.name
                    ? palette.primary[500]
                    : "white",
              }}
              width={"100%"}
              paddingX={"12px"}
              height={"48px"}
              paddingY={"6px"}
            >
              <Typography
                color={
                  onboardingCreateDto.industry == industry.name
                    ? "white"
                    : palette.text.secondary
                }
                fontSize={"12px"}
                fontWeight={500}
                textAlign={"center"}
              >
                {industry.name}
              </Typography>
            </Box>
          </Stack>
        ))}
      </Stack>
      {onboardingCreateDto.industry == "Other" ? (
        <Box marginTop={"24px"} width={"450px"}>
          <TextFieldNLC
            onChange={handleWriteOtherIndustry}
            value={onboardingCreateDto.other_description}
            icon={
              <Typography fontSize={"12px"} paddingX={"4px"}>
                Other
              </Typography>
            }
            label="Industry"
            placeholder="Please tell us your industry"
          />
        </Box>
      ) : null}
    </Stack>
  );
};

export default OnboardingStep2;
