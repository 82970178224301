import type { FC } from "react";

import { Box, ButtonBase } from "@mui/material";

import { usePopover } from "src/hooks/use-popover";
import { AccountPopover } from "./account-popover";

import useGlobalConfig from "src/modules/globals/hooks/useGlobalConfig";
import AvatarNLC from "src/modules/globals/components/avatarNLC";

export const AccountButton: FC = () => {
  const popover = usePopover<HTMLButtonElement>();
  const { profile } = useGlobalConfig();
  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "divider",
          height: 40,
          width: 40,
          borderRadius: "50%",
        }}
      >
        <AvatarNLC
          size="40px"
          src={
            profile?.avatar && profile.avatar.length ? profile.avatar[0] : "t"
          }
        />
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
