import { useRef, type FC } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import User01Icon from "@untitled-ui/icons-react/build/esm/User01";
import Mail04Icon from "@untitled-ui/icons-react/build/esm/Mail04";
import MessageChatSquareIcon from "@untitled-ui/icons-react/build/esm/MessageChatSquare";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import {
  Avatar,
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Scrollbar } from "src/components/scrollbar";
import { Notification } from "src/modules/globals/interfaces/notification";
import TabsNLC from "src/modules/globals/components/tabsNLC";
import { TabProp } from "src/modules/globals/components/tabItemNLC";
import NotificationItem from "./notificationItem";
import ButtonNLC from "src/modules/globals/components/buttonNLC";
import { useClickAway } from "react-use";

const renderContent = (notification: Notification): JSX.Element | null => {
  switch (notification.type) {
    case "job_add": {
      const createdAt = format(notification.createdAt, "MMM dd, h:mm a");

      return (
        <>
          <ListItemAvatar sx={{ mt: 0.5 }}>
            <Avatar src={notification.avatar}>
              <SvgIcon>
                <User01Icon />
              </SvgIcon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Typography sx={{ mr: 0.5 }} variant="subtitle2">
                  {notification.author}
                </Typography>
                <Typography sx={{ mr: 0.5 }} variant="body2">
                  added a new job
                </Typography>
                <Link href="#" underline="always" variant="body2">
                  {notification.job}
                </Link>
              </Box>
            }
            secondary={
              <Typography color="text.secondary" variant="caption">
                {createdAt}
              </Typography>
            }
            sx={{ my: 0 }}
          />
        </>
      );
    }
    case "new_feature": {
      const createdAt = format(notification.createdAt, "MMM dd, h:mm a");

      return (
        <>
          <ListItemAvatar sx={{ mt: 0.5 }}>
            <Avatar>
              <SvgIcon>
                <MessageChatSquareIcon />
              </SvgIcon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Typography variant="subtitle2" sx={{ mr: 0.5 }}>
                  New feature!
                </Typography>
                <Typography variant="body2">
                  {notification.description}
                </Typography>
              </Box>
            }
            secondary={
              <Typography color="text.secondary" variant="caption">
                {createdAt}
              </Typography>
            }
            sx={{ my: 0 }}
          />
        </>
      );
    }
    case "company_created": {
      const createdAt = format(notification.createdAt, "MMM dd, h:mm a");

      return (
        <>
          <ListItemAvatar sx={{ mt: 0.5 }}>
            <Avatar src={notification.avatar}>
              <SvgIcon>
                <User01Icon />
              </SvgIcon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexWrap: "wrap",
                  m: 0,
                }}
              >
                <Typography sx={{ mr: 0.5 }} variant="subtitle2">
                  {notification.author}
                </Typography>
                <Typography sx={{ mr: 0.5 }} variant="body2">
                  created
                </Typography>
                <Link href="#" underline="always" variant="body2">
                  {notification.company}
                </Link>
              </Box>
            }
            secondary={
              <Typography color="text.secondary" variant="caption">
                {createdAt}
              </Typography>
            }
            sx={{ my: 0 }}
          />
        </>
      );
    }
    default:
      return null;
  }
};

interface NotificationsPopoverProps {
  anchorEl: null | Element;
  notifications: Notification[];
  onClose?: () => void;
  onMarkAllAsRead?: () => void;
  onRemoveOne?: (id: string) => void;
  open?: boolean;
}

export const NotificationsPopover: FC<NotificationsPopoverProps> = (props) => {
  const {
    anchorEl,
    notifications,
    onClose,
    onMarkAllAsRead,
    onRemoveOne,
    open = false,
    ...other
  } = props;

  const popoverRef = useRef(null);

  useClickAway(popoverRef, () => {
    onClose?.();
  });

  const { palette } = useTheme();

  const isEmpty = notifications.length === 0;

  const tabs: TabProp[] = [
    {
      label: "All",
      value: "all",
      component: null,
    },
    {
      label: "Mentions",
      value: "mentions",
      component: null,
    },
    {
      label: "Request updates",
      value: "all",
      component: null,
    },
  ];

  return open ? (
    <Box
      ref={popoverRef}
      sx={{
        backgroundColor: palette.background.paper,
        width: "380px",
        position: "absolute",
        zIndex: 100,
        right: 0,
        top: "24px",
        borderRadius: "12px",
        boxShadow: "0px 0px 8px rgba(0,0,0,0.05)",
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          px: 3,
          py: 2,
        }}
      >
        <Typography
          color={palette.text.primary}
          fontSize={"20px"}
          fontWeight={500}
        >
          Notifications
        </Typography>

        <Typography
          onClick={onMarkAllAsRead}
          fontSize={"14px"}
          color={palette.info[500]}
          fontWeight={500}
          sx={{ cursor: "pointer" }}
        >
          Mark all as read
        </Typography>
      </Stack>
      <TabsNLC tabs={tabs} />
      {isEmpty ? (
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2">
            There are no notifications
          </Typography>
        </Box>
      ) : (
        <Scrollbar sx={{ maxHeight: 400 }}>
          <List disablePadding>
            {notifications.map((notification) => (
              <NotificationItem
                newMessage
                user={{
                  avatar: "te",
                  name: "John Oliveira",
                  username: "johnjohn",
                }}
                key={notification.id}
                account_name={"No Limit Creatives"}
                comment={[notification?.description ?? ""]}
                type={notification.type}
                createdAt={notification.createdAt}
              />
            ))}
          </List>
        </Scrollbar>
      )}
      <Stack
        padding={"16px"}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ButtonNLC text="See all notifications" />
      </Stack>
    </Box>
  ) : null;
};
