import { tokens } from "../tokens";

export const en = {
  [tokens.action.copyLink]: "Copy link",
  [tokens.action.delete]: "Delete",
  [tokens.common.languageChanged]: "Language changed",
  [tokens.create.announcement]: "Create a new announcement",
  [tokens.create.account]: "Create a new account",
  [tokens.create.brand]: "Create a new brand",
  [tokens.create.dashboardFeed]: "Create a feed link",
  [tokens.create.coupon]: "Create a new csoupon",
  [tokens.create.course]: "Create a new article",
  [tokens.create.category]: "Create a new category",
  [tokens.create.department]: "Create a new department",
  [tokens.create.maintenance]: "Set a new maintenance",
  [tokens.create.organization]: "Create a new organization",
  [tokens.create.request]: "Create a new request",
  [tokens.create.team]: "Create a new team",
  [tokens.create.user]: "Create a new user",
  [tokens.nav.academy]: "Academy",
  [tokens.nav.account]: "Account",
  [tokens.nav.accounts]: "Accounts",
  [tokens.nav.all]: "All",
  [tokens.nav.analytics]: "Analytics",
  [tokens.nav.announcements]: "Announcements",
  [tokens.nav.auth]: "Auth",
  [tokens.nav.automatedSuggestion]: "Automated suggestion",
  [tokens.nav.blacklist]: "Blacklist",
  [tokens.nav.blog]: "Blog",
  [tokens.nav.brands]: "Brands",
  [tokens.nav.browse]: "Browse",
  [tokens.nav.calendar]: "Calendar",
  [tokens.nav.categories]: "Categories",
  [tokens.nav.chat]: "Chat",
  [tokens.nav.checkout]: "Checkout",
  [tokens.nav.concepts]: "Concepts",
  [tokens.nav.contact]: "Contact",
  [tokens.nav.coupons]: "Coupons",
  [tokens.nav.course]: "Article",
  [tokens.nav.courses]: "Articles",
  [tokens.nav.create]: "Create",
  [tokens.nav.crypto]: "Crypto",
  [tokens.nav.dashboard]: "Dashboard",
  [tokens.nav.dashboardFeed]: "Dashboard feed",
  [tokens.nav.departments]: "Departments",
  [tokens.nav.details]: "Details",
  [tokens.nav.ecommerce]: "E-Commerce",
  [tokens.nav.edit]: "Edit",
  [tokens.nav.error]: "Error",
  [tokens.nav.feed]: "Feed",
  [tokens.nav.fileManager]: "File Manager",
  [tokens.nav.finance]: "Finance",
  [tokens.nav.fleet]: "Fleet",
  [tokens.nav.forgotPassword]: "Forgot Password",
  [tokens.nav.inactiveRequests]: "Inactive requests",
  [tokens.nav.inspiration]: "Inspiration",
  [tokens.nav.invoiceList]: "Invoices",
  [tokens.nav.jobList]: "Job Listings",
  [tokens.nav.kanban]: "Kanban",
  [tokens.nav.helpCenter]: "Help center",
  [tokens.nav.list]: "List",
  [tokens.nav.login]: "Login",
  [tokens.nav.logistics]: "Logistics",
  [tokens.nav.mail]: "Mail",
  [tokens.nav.maintenance]: "Maintenance",
  [tokens.nav.management]: "Management",
  [tokens.nav.orderList]: "Orders",
  [tokens.nav.organizations]: "Organizations",
  [tokens.nav.overview]: "Overview",
  [tokens.nav.pages]: "Pages",
  [tokens.nav.postCreate]: "Post Create",
  [tokens.nav.postDetails]: "Post Details",
  [tokens.nav.postList]: "Post List",
  [tokens.nav.pricing]: "Pricing",
  [tokens.nav.productList]: "Products",
  [tokens.nav.profile]: "Profile",
  [tokens.nav.register]: "Register",
  [tokens.nav.requests]: "Requests",
  [tokens.nav.reports]: "Reports",
  [tokens.nav.resetPassword]: "Reset Password",
  [tokens.nav.save]: "Save",
  [tokens.nav.socialMedia]: "Social Media",
  [tokens.nav.teamAssignment]: "Team assignment",
  [tokens.nav.teams]: "Teams",
  [tokens.nav.tools]: "Tools",
  [tokens.nav.users]: "Users",
  [tokens.nav.verifyCode]: "Verify Code",
  [tokens.nav.recycleBin]: "Recycle Bin",
  [tokens.nav.inspiration]: "Inspiration",
  [tokens.nav.billing]: "Billing",
  [tokens.nav.support]: "Support",
  [tokens.nav.notifications]: "Notifications",
  [tokens.nav.settings]: "Settings",
};
