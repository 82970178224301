import { useCallback } from "react";
import { ApiRoutes } from "src/modules/core/const/ApiRoutes";
import useAxios from "src/modules/core/hooks/useAxios";
import { OnboardingTaxonomies, StoreOnboardingDto } from "../interface";
import { GlobalDefaultResponseInterface } from "src/modules/globals/interfaces/globalDefaultResponseInterface/globalDefaultResponseInterface";

const OnboardingRepository = () => {
  const axios = useAxios();
  const fetchOnboarding = useCallback(async (username: string) => {
    try {
      const response = await axios.get(
        ApiRoutes.ONBOARDING + "?username=" + username,
      );
      return {
        data: response.data,
        isSuccess: true,
        isError: false,
      };
    } catch (error) {
      return {
        data: {},
        isSuccess: false,
        isError: true,
        error: error.response.data.message ?? "Error",
      };
    }
  }, []);

  const onboardingTaxonomy = useCallback(async (): Promise<
    GlobalDefaultResponseInterface<OnboardingTaxonomies>
  > => {
    try {
      const response = await axios.get(ApiRoutes.ONBOARDING_TAXONOMY);
      return {
        data: { data: response.data.data },
        isSuccess: true,
        isError: false,
      };
    } catch (error) {
      return {
        data: {},
        isSuccess: false,
        isError: true,
        error: error.response.data.message ?? "Error",
      };
    }
  }, []);

  const storeOnboarding = useCallback(async (data: StoreOnboardingDto) => {
    try {
      const response = await axios.post(ApiRoutes.ONBOARDING, data);
      return {
        data: response.data,
        isSuccess: true,
        isError: false,
      };
    } catch (error) {
      return {
        data: {},
        isSuccess: false,
        isError: true,
        error: error.response.data.message ?? "Error",
      };
    }
  }, []);

  return {
    fetchOnboarding,
    onboardingTaxonomy,
    storeOnboarding,
  };
};

export default OnboardingRepository;
