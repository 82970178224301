import type { ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Chip, SvgIcon } from "@mui/material";
import AlignLeft02Icon from "src/icons/untitled-ui/duocolor/align-left-02";
import BarChartSquare02Icon from "src/icons/untitled-ui/duocolor/bar-chart-square-02";
import Building04Icon from "src/icons/untitled-ui/duocolor/building-04";
import CalendarIcon from "src/icons/untitled-ui/duocolor/calendar";
import CheckDone01Icon from "src/icons/untitled-ui/duocolor/check-done-01";
import CreditCard01Icon from "src/icons/untitled-ui/duocolor/credit-card-01";
import CurrencyBitcoinCircleIcon from "src/icons/untitled-ui/duocolor/currency-bitcoin-circle";
import File01Icon from "src/icons/untitled-ui/duocolor/file-01";
import GraduationHat01Icon from "src/icons/untitled-ui/duocolor/graduation-hat-01";
import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import LayoutAlt02Icon from "src/icons/untitled-ui/duocolor/layout-alt-02";
import LineChartUp04Icon from "src/icons/untitled-ui/duocolor/line-chart-up-04";
import Lock01Icon from "src/icons/untitled-ui/duocolor/lock-01";
import LogOut01Icon from "src/icons/untitled-ui/duocolor/log-out-01";
import Mail03Icon from "src/icons/untitled-ui/duocolor/mail-03";
import Mail04Icon from "src/icons/untitled-ui/duocolor/mail-04";
import MessageChatSquareIcon from "src/icons/untitled-ui/duocolor/message-chat-square";
import ReceiptCheckIcon from "src/icons/untitled-ui/duocolor/receipt-check";
import Share07Icon from "src/icons/untitled-ui/duocolor/share-07";
import ShoppingBag03Icon from "src/icons/untitled-ui/duocolor/shopping-bag-03";
import ShoppingCart01Icon from "src/icons/untitled-ui/duocolor/shopping-cart-01";
import Truck01Icon from "src/icons/untitled-ui/duocolor/truck-01";
import Upload04Icon from "src/icons/untitled-ui/duocolor/upload-04";
import Users03Icon from "src/icons/untitled-ui/duocolor/users-03";
import XSquareIcon from "src/icons/untitled-ui/duocolor/x-square";
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useGlobalConfig from "src/modules/globals/hooks/useGlobalConfig";
import { RoleTypes } from "src/modules/roles/interfaces/roles";

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {
  const { t } = useTranslation();
  const { permissions } = useGlobalConfig();
  return useMemo(() => {
    return [
      {
        subheader: "Main menu",
        items: [
          {
            title: t(tokens.nav.dashboard),
            path: paths.dashboard.index,
            action: "App\\Actions\\UserActions\\SeeDashboardAction",
            icon: (
              <SvgIcon sx={{ fontSize: 16 }}>
                <FontAwesomeIcon icon={["far", "smile"]} />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.notifications),
            path: paths.dashboard.notifications,
            icon: (
              <SvgIcon sx={{ fontSize: 16 }}>
                <FontAwesomeIcon icon={["far", "bell"]} />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.organizations),
            path: paths.dashboard.organizations,
            action: "App\\Actions\\OrganizationActions\\SeeOrganizationAction",
            icon: (
              <SvgIcon sx={{ fontSize: 16 }}>
                <FontAwesomeIcon icon={["far", "building"]} />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.departments),
            path: paths.dashboard.departments,
            action: "App\\Actions\\DepartmentActions\\SeeDepartmentAction",
            icon: (
              <SvgIcon sx={{ fontSize: 16 }}>
                <FontAwesomeIcon icon={["far", "building-user"]} />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.teams),
            path: paths.dashboard.teams,
            action: "App\\Actions\\TeamActions\\SeeTeamsAction",
            icon: (
              <SvgIcon sx={{ fontSize: 16 }}>
                <FontAwesomeIcon icon={["far", "user-group"]} />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.accounts),
            path: paths.dashboard.accounts,
            action: "App\\Actions\\AccountActions\\SeeAccountAction",
            icon: (
              <SvgIcon sx={{ fontSize: 16 }}>
                <FontAwesomeIcon icon={["far", "cabinet-filing"]} />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.brands),
            path: paths.dashboard.brands,
            action: "App\\Actions\\BrandActions\\SeeBrandAction",
            icon: (
              <SvgIcon sx={{ fontSize: 16 }}>
                <FontAwesomeIcon icon={["far", "medal"]} />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.users),
            path: paths.dashboard.users,
            action: "App\\Actions\\UserActions\\SeeUserAction",
            icon: (
              <SvgIcon sx={{ fontSize: 16 }}>
                <FontAwesomeIcon icon={["far", "user"]} />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.requests),
            path: paths.dashboard.requests.index,
            action: "App\\Actions\\RequestActions\\SeeRequestAction",
            icon: (
              <SvgIcon sx={{ fontSize: 16 }}>
                <FontAwesomeIcon
                  icon={["far", "wand-magic-sparkles"]}
                  size="xs"
                />
              </SvgIcon>
            ),
          },
        ].filter(
          (item: any) =>
            !item.action ||
            permissions?.some((perm) => perm.action == item.action),
        ),
      },

      {
        subheader: "",
        items: [
          {
            title: t(tokens.nav.billing),
            path: paths.dashboard.billing,
            action:
              "App\\Actions\\UserSettingsActions\\SeeUserBillingInfoAction",
            icon: (
              <SvgIcon sx={{ fontSize: 16 }}>
                <FontAwesomeIcon icon={["far", "credit-card"]} />
              </SvgIcon>
            ),
          },

          {
            title: t(tokens.nav.settings),
            path: paths.dashboard.settings,
            action: "App\\Actions\\UserActions\\ManagePortalAction",
            icon: (
              <SvgIcon sx={{ fontSize: 16 }}>
                <FontAwesomeIcon icon={["far", "toolbox"]} />
              </SvgIcon>
            ),
          },

          {
            title: t(tokens.nav.helpCenter),
            path: paths.dashboard.helpCenter,
            icon: (
              <SvgIcon sx={{ fontSize: 16 }}>
                <FontAwesomeIcon icon={["far", "circle-question"]} />
              </SvgIcon>
            ),
          },
        ].filter(
          (item: any) =>
            !item.action ||
            permissions?.some((perm) => perm.action == item.action),
        ),
      },
    ].filter(
      (item: any) =>
        !item.action || permissions?.some((perm) => perm.action == item.action),
    );
  }, [t, permissions]);
};
