import type { PaletteOptions } from "@mui/material";

import { neutral } from "../colors";
import type { ColorPreset, Contrast } from "../index";

interface Config {
  colorPreset?: ColorPreset;
  contrast?: Contrast;
}

export const createPalette = (config: Config): PaletteOptions => {
  const { contrast } = config;

  return {
    mode: "dark",
    primary: {
      main: "#7c4dff",
      50: "#efe7ff",
      200: "#D8ACFF",
      100: "#d5c3fe",
      300: "#C5B0FF",
      400: "#A686FF",
      500: "#7c4dff",
      600: "#4d21f7",
      700: "#3e1be1",
      800: "#2f14cb",
      900: "#210ea6",
      light: "#d5c3fe",
      dark: "#210ea6",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#667085",
      50: "#eeeff4",
      100: "#d4d5db",
      200: "#b6b7bf",
      300: "#9DA4B4",
      400: "#7d808b",
      500: "#646775",
      600: "#4f515f",
      700: "#3b3c49",
      800: "#272832",
      900: "#14151c",
      light: "#b6b7bf",
      dark: "#14151c",
      contrastText: "#ffffff",
    },
    info: {
      main: "#2e90fa",
      50: "#e9f2ff",
      100: "#c7dafd",
      200: "#a2c1fa",
      300: "#7da8f7",
      400: "#46a1fc",
      500: "#2e90fa",
      600: "#287fe3",
      700: "#2169c2",
      800: "#1a52a1",
      900: "#133b80",
      light: "#46a1fc",
      dark: "#133b80",
      contrastText: "#ffffff",
    },
    warning: {
      main: "#f79009",
      50: "#fef3e1",
      100: "#fce0b8",
      200: "#f9cc8d",
      300: "#f6b862",
      400: "#f59e3a",
      500: "#f79009",
      600: "#f37f08",
      700: "#f06d07",
      800: "#ed5b06",
      900: "#e84104",
      light: "#f59e3a",
      dark: "#e84104",
      contrastText: "#ffffff",
    },
    error: {
      main: "#f04438",
      50: "#feebee",
      100: "#fccfd0",
      200: "#faa1a2",
      300: "#f77374",
      400: "#f44b4b",
      500: "#f04438",
      600: "#eb3e33",
      700: "#e5372d",
      800: "#e03027",
      900: "#d9221d",
      light: "#f44b4b",
      dark: "#d9221d",
      contrastText: "#ffffff",
    },
    success: {
      main: "#10b981",
      50: "#e2f5ec",
      100: "#b6e7d3",
      200: "#85d9b9",
      300: "#54cb9f",
      400: "#2cbf89",
      500: "#10b981",
      600: "#0daa77",
      700: "#0a9b6c",
      800: "#078c62",
      900: "#05704e",
      light: "#2cbf89",
      dark: "#05704e",
      contrastText: "#ffffff",
    },
    neutral,
    text: {
      primary: "#EDF2F7",
      secondary: "#A0AEC0",
      disabled: "rgba(255, 255, 255, 0.48)",
    },
    background: {
      default: contrast === "high" ? "#0B0F19" : "#0E1320",
      paper: neutral[900],
    },
  };
};
