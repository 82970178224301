import { tokens } from "../tokens";

export const de = {
  [tokens.action.copyLink]: "Link kopieren",
  [tokens.common.languageChanged]: "Sprache geändert",
  [tokens.create.account]: "Ein neues Konto erstellen",
  [tokens.create.announcement]: "Eine neue Ankündigung erstellen",
  [tokens.create.brand]: "Eine neue Marke erstellen",
  [tokens.create.category]: "Eine neue Kategorie erstellen",
  [tokens.create.coupon]: "Neuen Gutschein erstellen",
  [tokens.create.course]: "Erstelle einen neuen Artikel",
  [tokens.create.dashboardFeed]: "Erstellen Sie einen Feed-Link",
  [tokens.create.department]: "Eine neue Abteilung erstellen",
  [tokens.create.maintenance]: "Eine neue Wartung einrichten",
  [tokens.create.organization]: "Eine neue Organisation gründen",
  [tokens.create.request]: "Erstellen Sie eine neue Anfrage",
  [tokens.create.team]: "Ein neues Team erstellen",
  [tokens.create.user]: "Einen neuen Benutzer erstellen",
  [tokens.nav.academy]: "Akademie",
  [tokens.nav.account]: "Konto",
  [tokens.nav.accounts]: "Konten",
  [tokens.nav.all]: "Alle",
  [tokens.nav.analytics]: "Analytik",
  [tokens.nav.announcements]: "Ankündigungen",
  [tokens.nav.auth]: "Authentifizierung",
  [tokens.nav.automatedSuggestion]: "Automatischer Vorschlag",
  [tokens.nav.blacklist]: "Schwarze Liste",
  [tokens.nav.blog]: "Blog",
  [tokens.nav.brands]: "Brands",
  [tokens.nav.browse]: "Durchsuche",
  [tokens.nav.calendar]: "Kalender",
  [tokens.nav.categories]: "Kategorien",
  [tokens.nav.chat]: "Plaudern",
  [tokens.nav.checkout]: "Auschecken",
  [tokens.nav.concepts]: "Konzepte",
  [tokens.nav.contact]: "Kontakt",
  [tokens.nav.coupons]: "Gutscheine",
  [tokens.nav.course]: "Artikel",
  [tokens.nav.courses]: "Artikel",
  [tokens.nav.create]: "Schaffen",
  [tokens.nav.crypto]: "Crypto",
  [tokens.nav.dashboard]: "Dashboard",
  [tokens.nav.dashboardFeed]: "Dashboard-Feed",
  [tokens.nav.departments]: "Abteilungen",
  [tokens.nav.details]: "Einzelheiten",
  [tokens.nav.ecommerce]: "E-Commerce",
  [tokens.nav.edit]: "Bearbeiten",
  [tokens.nav.error]: "Fehler",
  [tokens.nav.feed]: "Füttern",
  [tokens.nav.fileManager]: "Dateimanager",
  [tokens.nav.finance]: "Finanzen",
  [tokens.nav.fleet]: "Flotte",
  [tokens.nav.forgotPassword]: "Passwort Vergessen",
  [tokens.nav.helpCenter]: "Hilfe-Center",
  [tokens.nav.inactiveRequests]: "Inaktive Anfragen",
  [tokens.nav.inspiration]: "Inspiration",
  [tokens.nav.invoiceList]: "Rechnungen",
  [tokens.nav.jobList]: "Stellenangebote",
  [tokens.nav.kanban]: "Kanban",
  [tokens.nav.list]: "Aufführen",
  [tokens.nav.login]: "Anmeldung",
  [tokens.nav.logistics]: "Logistik",
  [tokens.nav.mail]: "E-Mail",
  [tokens.nav.maintenance]: "Wartung",
  [tokens.nav.management]: "Verwaltung",
  [tokens.nav.orderList]: "Aufträge",
  [tokens.nav.organizations]: "Organisationen",
  [tokens.nav.overview]: "Überblick",
  [tokens.nav.pages]: "Seiten",
  [tokens.nav.postCreate]: "Beitrag erstellen",
  [tokens.nav.postDetails]: "Beitragsdetails",
  [tokens.nav.postList]: "Beitragsliste",
  [tokens.nav.pricing]: "Preisgestaltung",
  [tokens.nav.productList]: "Produkte",
  [tokens.nav.profile]: "Profil",
  [tokens.nav.register]: "Registrieren",
  [tokens.nav.reports]: "Berichte",
  [tokens.nav.requests]: "Anfragen",
  [tokens.nav.resetPassword]: "Passwort Zurücksetzen",
  [tokens.nav.save]: "Speichern",
  [tokens.nav.socialMedia]: "Sozialen Medien",
  [tokens.nav.teamAssignment]: "Teamzuweisung",
  [tokens.nav.teams]: "Teams",
  [tokens.nav.tools]: "Werkzeuge",
  [tokens.nav.users]: "Benutzer",
  [tokens.nav.verifyCode]: "Code Überprüfen",
};
