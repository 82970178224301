import { Box, useTheme } from "@mui/material";

import { ReactNode, useRef } from "react";
import { useClickAway, useLockBodyScroll } from "react-use";

interface ModalNLCProps {
  open?: boolean;
  onClose?: () => void;
  children?: ReactNode;
  width?: string;
  heigth?: string;
  overlay?: boolean;
}
const ModalNLC = ({
  open = true,
  onClose,
  children,
  width = "500px",
  heigth = "auto",
  overlay = true,
}: ModalNLCProps) => {
  const { palette } = useTheme();
  const modalref = useRef<HTMLDivElement>(null);
  useClickAway(modalref, () => {
    if (open) onClose?.();
  });
  useLockBodyScroll(open);

  return open ? (
    <Box
      sx={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 1201,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          bgcolor: overlay ? "rgba(0,0,0,0.3)" : "transparent",
          width: "100vw",
          height: "100vh",
          zIndex: -1,
        }}
      ></Box>
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
          left: 0,
          top: 0,
        }}
      >
        <Box
          sx={{
            width: width,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            ref={modalref}
            sx={{
              width: "100%",
              padding: "24px",
              height: heigth,
              maxHeight: heigth,

              bgcolor: palette.background.paper,
              borderRadius: "12px",
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default ModalNLC;
