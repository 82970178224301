import { Box, useTheme } from "@mui/material";
import OnboardingProvider from "../providers/onboardingProvider";
import useGuardPages from "src/modules/globals/hooks/useGuardPages";
import { Logo } from "src/components/logo";
import ModalNLC from "src/modules/globals/components/modalNLC";
import OnboardingSteps from "../components/onboardingSteps";
import useOnboarding from "../hooks/useOnboarding";

const _OnboardingTemplate = () => {
  const { onboardingTaxonomies, onSaveOnboarding } = useOnboarding();
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        backgroundColor: palette.grey[50],
      }}
    >
      <Box
        sx={{
          height: "30px",
          width: "160px",
          position: "absolute",
          zIndex: 10,
          top: "50px",
          left: "60px",
        }}
      >
        <Logo inverted={false} />
      </Box>
      <ModalNLC width="auto" overlay={false} open={true}>
        <OnboardingSteps
          onSaveOnboarding={onSaveOnboarding}
          onboardingTaxonomies={onboardingTaxonomies}
        />
      </ModalNLC>
    </Box>
  );
};

const OnboardingTemplate = () => {
  useGuardPages({ loggedIn: true });
  return (
    <OnboardingProvider>
      <_OnboardingTemplate />
    </OnboardingProvider>
  );
};

export default OnboardingTemplate;
