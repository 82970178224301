import { createContext } from "react";
import { OnboardingTaxonomies, StoreOnboardingDto } from "../../interface";

export interface OnboardingContextProps {
  onboardingTaxonomies?: OnboardingTaxonomies;
  onSaveOnboarding?: (data: StoreOnboardingDto) => void;
}

const OnboardingContext = createContext<OnboardingContextProps>({});

export default OnboardingContext;
