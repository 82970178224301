import type { PaletteColor } from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import type { NeutralColors } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";

const withAlphas = (color: PaletteColor): PaletteColor => {
  return {
    ...color,
    alpha4: alpha(color.main, 0.04),
    alpha8: alpha(color.main, 0.08),
    alpha12: alpha(color.main, 0.12),
    alpha30: alpha(color.main, 0.3),
    alpha50: alpha(color.main, 0.5),
  };
};

export const neutral: NeutralColors = {
  50: "#F8F9FA",
  100: "#F3F4F6",
  200: "#E5E7EB",
  300: "#9DA4B4",
  400: "#9DA4AE",
  500: "#6C737F",
  600: "#4D5761",
  700: "#2F3746",
  800: "#1C2536",
  900: "#111927",
};

export const blue = withAlphas({
  lightest: "#F5F8FF",
  light: "#EBEFFF",
  main: "#2970FF",
  dark: "#004EEB",
  darkest: "#00359E",
  contrastText: "#FFFFFF",
});

export const green = withAlphas({
  lightest: "#F6FEF9",
  light: "#EDFCF2",
  main: "#16B364",
  dark: "#087443",
  darkest: "#084C2E",
  contrastText: "#FFFFFF",
});

export const indigo = withAlphas({
  lightest: "#F5F7FF",
  light: "#EBEEFE",
  main: "#6366F1",
  dark: "#4338CA",
  darkest: "#312E81",
  contrastText: "#FFFFFF",
});

export const info: NeutralColors = {
  50: "#EFF8FF",
  100: "#D1E9FF",
  200: "#B2DDFF",
  300: "",
  400: "#53B1FD",
  500: "#2E90FA",
  600: "#1570EF",
  700: "#175CD3",
  800: "#1849A9",
  900: "#194185",
  main: "#2E90FA",
  light: "#D1E9FF",
  dark: "#175CD3",
  contrastText: "",
};

export const primaryColors = {
  main: "#7c4dff",
  50: "#efe7ff",
  200: "#D8ACFF",
  100: "#d5c3fe",
  300: "#C5B0FF",
  400: "#A686FF",
  500: "#7c4dff",
  600: "#4d21f7",
  700: "#3e1be1",
  800: "#2f14cb",
  900: "#210ea6",
  light: "#d5c3fe",
  dark: "#210ea6",
  contrastText: "#ffffff",
};

export const purple = withAlphas({
  lightest: "#E5DBFF",
  light: "#A686FF",
  main: "#7C4DFF",
  dark: "#6B35FF",
  darkest: deepPurple[700],
  contrastText: "#FFFFFF",
});

export const success = withAlphas({
  lightest: "#F0FDF9",
  light: "#3FC79A",
  main: "#10B981",
  dark: "#0B815A",
  darkest: "#134E48",
  contrastText: "#FFFFFF",
});

export const primary: NeutralColors = {
  50: "#EFF8FF",
  100: "#E5DBFF",
  200: "#D8ACFF",
  300: "#D2D6DB",
  400: "#A686FF",
  500: "#7C4DFF",
  600: "#6B35FF",
  700: "#5E27F5",
  800: "#4112C2",
  900: "#200666",
  main: "#7C4DFF",
  light: "#E5DBFF",
  dark: "#6B35FF",
  contrastText: "#FFFFFF",
};

export const warning = withAlphas({
  lightest: "#FFFAEB",
  light: "#FEF0C7",
  main: "#F79009",
  dark: "#B54708",
  darkest: "#7A2E0E",
  contrastText: "#FFFFFF",
});

export const error = withAlphas({
  lightest: "#FEF3F2",
  light: "#FEE4E2",
  main: "#F04438",
  dark: "#B42318",
  darkest: "#7A271A",
  contrastText: "#FFFFFF",
});

export const grey: NeutralColors = {
  50: "#EEEFF2",
  100: "#DCDFE4",
  200: "#C0C4CE",
  300: "#9DA4B4",
  400: "#80899D",
  500: "#667085",
  600: "#535B6C",
  700: "#353A45",
  800: "#262931",
  900: "#1A1D22",
  main: "#667085",
  light: "#DCDFE4",
  dark: "#535B6C",
  contrastText: "#FFFFFF",
};
