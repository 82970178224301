export const paths = {
  index: "/",
  checkout: "/checkout",
  auth: {
    jwt: {
      login: "/auth/jwt/login",
    },
  },
  dashboard: {
    index: "/dashboard",
    helpCenter: "/dashboard/help-center",
    accounts: "/dashboard/accounts",
    brands: "/dashboard/brands",
    billing: "/user/settings",
    notifications: "/dashboard/notifications",
    onboarding: "/dashboard/onboarding",
    settings: "/dashboard/settings",
    blank: "/dashboard/blank",
    users: "/dashboard/users",
    organizations: "/dashboard/organizations",
    products: {
      index: "/dashboard/products",
    },
    requests: {
      index: "/dashboard/requests",
      create: "/dashboard/requests/create",
    },
    departments: "/dashboard/departments",
    teams: "/dashboard/teams",
  },
  401: "/401",
  404: "/404",
  500: "/500",
};
