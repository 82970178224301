import { ReactNode, useState } from "react";
import { Alert, AlertContext, AlertType } from "../context/alertContext";
import AlertNLC from "../components/alertNLC";
import { generateRandomUUID } from "../utils/uuid";
import { Box } from "@mui/material";

const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const createAlert = (alert: Alert) => {
    setAlerts((alerts) => [...alerts, alert]);
    setTimeout(() => removeAlertById(alert.id), alert.timeout ?? 5000);
  };

  const removeAlertById = (id: string) => {
    setAlerts((alerts) => alerts.filter((alert) => alert.id !== id));
  };

  return (
    <AlertContext.Provider value={{ alerts, createAlert }}>
      {children}
      <Box
        sx={{
          position: "fixed",
          right: "24px",
          bottom: "24px",
          zIndex: 1000000000,
        }}
      >
        {alerts.map((alert) => (
          <AlertNLC
            onClose={alert.onClose}
            type={alert.type}
            icon={alert.icon}
            sx={{ minWidth: "600px", marginTop: "24px" }}
            messages={alert.messages}
            key={generateRandomUUID()}
          />
        ))}
      </Box>
    </AlertContext.Provider>
  );
};

export default AlertProvider;
