import { Box, Stack, Typography, useTheme } from "@mui/material";
import { formatRelative } from "date-fns";
import AvatarNLC from "src/modules/globals/components/avatarNLC";
import BadgeNLC from "src/modules/globals/components/badgeNLC";

interface NotificationItemProps {
  user: { avatar: string; name: string; username: string };
  account_name: string;
  comment: string[];
  type: string;
  createdAt: number;
  newMessage: boolean;
  onClick?: () => void;
}

const NotificationItem = ({
  user,
  account_name,
  comment,
  type,
  createdAt,
  newMessage,
  onClick,
}: NotificationItemProps) => {
  const { palette } = useTheme();
  return (
    <Stack
      onClick={onClick}
      spacing={"20px"}
      sx={{
        padding: "12px 32px",
        borderBottom: "0.5px solid rgba(0,0,0,0.1)",
        backgroundColor: newMessage
          ? palette.background.default
          : "transparent",
      }}
      direction={"row"}
    >
      <AvatarNLC src={user.avatar} />
      <Box flex={1}>
        <Stack justifyContent={"space-between"} direction={"row"}>
          <Typography
            fontSize={"14px"}
            fontWeight={600}
            color={palette.text.primary}
          >
            {user.name}
          </Typography>
          <Stack alignItems={"center"} spacing={"8px"} direction={"row"}>
            <Typography fontSize={"12px"} color={palette.text.secondary}>
              {formatRelative(createdAt, Date.now())}
            </Typography>
            {newMessage && (
              <Box
                sx={{
                  height: "8px",
                  width: "8px",
                  borderRadius: "100px",
                  backgroundColor: palette.info[500],
                }}
              ></Box>
            )}
          </Stack>
        </Stack>

        <Typography
          fontSize={"12px"}
          fontWeight={400}
          color={palette.text.secondary}
        >
          {account_name}
        </Typography>
        <Box marginBottom={"8px"} marginTop={"8px"}>
          {comment.map((c, ind) => (
            <Typography key={ind} fontSize={"12px"} color={palette.grey[700]}>
              - {c}
            </Typography>
          ))}
        </Box>
        <Stack direction={"row"}>
          <BadgeNLC text={type} />
        </Stack>
      </Box>
    </Stack>
  );
};

export default NotificationItem;
