import { createTheme } from "@mui/material/styles";

import { typography } from "./typography";
import {
  baseColor,
  components,
  shadowColors,
  textDisabled,
  textSecondary,
} from "./colors";

const auroraTheme = createTheme({
  mixins: {
    toolbar: {},
    components: {
      badge: {
        ...components.badge,
      },
      drawerAddon: {
        ...components.drawerAddon,
      },
      drawerMainInfo: {
        ...components.drawerMainInfo,
      },
      tag: {
        ...components.tag,
      },
      textField: {
        ...components.textField,
      },
    },
  },
  palette: {
    primary: {
      main: baseColor,
    },
    secondary: {
      main: baseColor,
      dark: baseColor,
    },
    info: {
      main: baseColor,
    },
    error: {
      light: baseColor,
      main: baseColor,
      dark: baseColor,
    },
    warning: {
      light: baseColor,
      main: baseColor,
      dark: baseColor,
    },
    success: {
      light: baseColor,
      main: baseColor,
      dark: baseColor,
    },
    background: {
      default: baseColor,
    },
    text: {
      primary: "#262626",
      secondary: textSecondary[0],
      disabled: textDisabled[0],
    },
    divider: "#f0f0f0",
    common: {
      white: "#ffffff",
      black: "#000000",
    },
    neutral: {
      light: shadowColors[0],
      50: "",
      100: "",
      200: "",
      300: "",
      400: "",
      500: "",
      600: "",
      700: "",
      800: "",
      900: "",
    },
  },
  typography: {
    fontFamily: typography.fontFamily[0],
    h1: {
      fontSize: 38,
      lineHeight: 1.21,
      fontWeight: 600,
    },
    h2: {
      fontSize: 30,
      lineHeight: 1.27,
      fontWeight: 600,
    },
    h3: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.33,
    },
    h4: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h6: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.57,
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.57,
    },
    body2: {
      fontSize: 12,
      lineHeight: 1.66,
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.57,
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.66,
    },
    overline: {
      lineHeight: 1.66,
    },
  },
});

export default auroraTheme;
