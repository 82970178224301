import { Box, Stack, Typography, useTheme } from "@mui/material";
import { palette } from "@mui/system";
import MostImportantComponent from "../mostImportantComponent";
import { OnboardingTaxonomies, StoreOnboardingDto } from "../../interface";
import { Dispatch, SetStateAction } from "react";

interface OnboardingStep4Props {
  onboardingCreateDto: StoreOnboardingDto;
  setOnboardingCreateDto: Dispatch<SetStateAction<StoreOnboardingDto>>;
  onboardingTaxonomies?: OnboardingTaxonomies;
}
const OnboardingStep4 = ({
  onboardingCreateDto,
  setOnboardingCreateDto,
  onboardingTaxonomies,
}: OnboardingStep4Props) => {
  const { palette } = useTheme();

  const productionItens: { name: string; label: string }[] = [
    { name: "Quality", label: "quality" },
    { name: "Speed", label: "speed" },
    { name: "Communication", label: "communication" },
    { name: "Creativity", label: "creativity" },
  ];

  const graphicItens: { name: string; label: string }[] = [
    { name: "Social Media", label: "social_media" },
    { name: "Amazon", label: "amazon" },
    { name: "Email", label: "email" },
    { name: "Print", label: "print" },
    { name: "Youtube", label: "youtube" },
    { name: "Linkedin", label: "linkedin" },
  ];

  const onChangeOrderProduction = (
    objects: { name: string; label: string }[],
  ) => {
    setOnboardingCreateDto((prev) => ({
      ...prev,
      production_order: objects.map((item) => item.name),
    }));
  };

  const handleSelectOneGraphic = (object: { name: string; label: string }) => {
    setOnboardingCreateDto((prev) => ({
      ...prev,
      graphic_design: [...prev.graphic_design, object.name],
    }));
  };

  const handleSelectOneVideo = (object: { name: string; label: string }) => {
    setOnboardingCreateDto((prev) => ({
      ...prev,
      video_design: [...prev.video_design, object.name],
    }));
  };

  return (
    <Stack direction={"column"} justifyContent={"center"} alignItems={"center"}>
      <Typography fontSize={"24px"} fontWeight={600} textAlign={"center"}>
        What's most important to you?
      </Typography>
      <Typography
        fontSize={"14px"}
        color={palette.text.secondary}
        fontWeight={400}
        textAlign={"center"}
      >
        We aim to understand what you care about the most to provide the best
        experience
        <br />
        and a strong start to our design partnership.
      </Typography>
      <Stack marginTop={"50px"} direction={"row"}>
        <MostImportantComponent
          onChangeOrder={onChangeOrderProduction}
          sx={{
            paddingRight: "16px",
            borderRight: `1px solid ${palette.grey[50]}`,
          }}
          title={"Production:"}
          subtitle={
            "Drag and arrange the items below from the most important to the least"
          }
          isDraggable={true}
          objects={onboardingCreateDto?.production_order.map((item) => ({
            name: item,
            label: item,
          }))}
        />
        <MostImportantComponent
          selected={onboardingCreateDto?.graphic_design.map((item) => ({
            name: item,
            label: item,
          }))}
          onSelectOne={handleSelectOneGraphic}
          sx={{ padding: "0px 16px" }}
          title={"Graphic design:"}
          subtitle={
            "What types of graphic designs are you planning to use the most?"
          }
          isDraggable={false}
          objects={
            onboardingTaxonomies?.graphic_design_types.map((it) => ({
              name: it,
              label: it,
            })) ?? []
          }
        />
        <MostImportantComponent
          selected={onboardingCreateDto?.video_design.map((item) => ({
            name: item,
            label: item,
          }))}
          onSelectOne={handleSelectOneVideo}
          sx={{
            paddingLeft: "16px",
            borderLeft: `1px solid ${palette.grey[50]}`,
          }}
          title={"Video design:"}
          subtitle={
            "What types of video designs are you planning to use the most?"
          }
          isDraggable={false}
          objects={
            onboardingTaxonomies?.video_design_types.map((it) => ({
              name: it,
              label: it,
            })) ?? []
          }
        />
      </Stack>
    </Stack>
  );
};

export default OnboardingStep4;
