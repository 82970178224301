import { ApiRoutes } from "src/modules/core/const/ApiRoutes";
import useAxios from "src/modules/core/hooks/useAxios";
import { GlobalDefaultResponseInterface } from "../interfaces/globalDefaultResponseInterface/globalDefaultResponseInterface";
import { Taxonomies } from "../interfaces/taxonomies";
import { Profile } from "../interfaces/profile";
import { useCallback } from "react";
import Permissions from "../interfaces/permissions";

const GlobalRepository = () => {
  const axios = useAxios();
  const fetchTaxonomies = async (): Promise<
    GlobalDefaultResponseInterface<Taxonomies>
  > => {
    try {
      const data = await axios.get(ApiRoutes.TAXONOMIES);
      return {
        data: data.data,
        isError: false,
        isSuccess: true,
      };
    } catch (error) {
      return {
        isError: false,
        isSuccess: true,
        error: error.response.data.message ?? "Error",
      };
    }
  };

  const getUserProfile = async (): Promise<
    GlobalDefaultResponseInterface<Profile>
  > => {
    try {
      const data = await axios.get(ApiRoutes.USER_PROFILE);
      return {
        data: { data: data.data.data },
        isError: false,
        isSuccess: true,
      };
    } catch (error) {
      return {
        isError: false,
        isSuccess: true,
        error: error.response.data.message ?? "Error",
      };
    }
  };

  const startImpersonate = useCallback(async (username: string) => {
    try {
      const data = await axios.get(
        ApiRoutes.IMPERSONATE + "?username=" + username,
      );
      return {
        data: data.data.data,
        isError: false,
        isSuccess: true,
      };
    } catch (error) {
      return {
        isError: false,
        isSuccess: true,
        error: error.response.data.message ?? "Error",
      };
    }
  }, []);

  const stopImpersonate = useCallback(async () => {
    try {
      await axios.delete(ApiRoutes.IMPERSONATE);
      return {
        data: "0k",
        isError: false,
        isSuccess: true,
      };
    } catch (error) {
      return {
        isError: false,
        isSuccess: true,
        error: error.response.data.message ?? "Error",
      };
    }
  }, []);

  const getUserPermissions = useCallback(async (): Promise<
    GlobalDefaultResponseInterface<Permissions[]>
  > => {
    try {
      const data = await axios.get(ApiRoutes.USER_PERMISSIONS);
      return {
        data: { data: data.data.data },
        isError: false,
        isSuccess: true,
      };
    } catch (error) {
      return {
        isError: false,
        isSuccess: true,
        error: error.response.data.message ?? "Error",
      };
    }
  }, []);

  return {
    fetchTaxonomies,
    getUserProfile,
    startImpersonate,
    stopImpersonate,
    getUserPermissions,
  };
};

export default GlobalRepository;
