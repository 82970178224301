/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import TabItemNLC, { TabProp } from "../tabItemNLC";
import { generateRandomUUID } from "../../utils/uuid";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";

interface TabsNLCProps {
  tabs?: TabProp[];
  size?: "sm" | "md";
  color?: "primary" | "secondary" | "info" | "warning" | "success" | "error";
  variant?: "transparent" | "text";
  actualTab?: number;
  showComponents?: boolean;
  align?: "left" | "center" | "right";
  onTabChange?: (id: TabProp) => void;
  sx?: any;
}

const TabsNLC = ({
  tabs = [],
  size = "md",
  color,
  variant,
  actualTab = 0,
  showComponents = true,
  align = "left",
  sx,
  onTabChange,
}: TabsNLCProps) => {
  const [tabSelected, setTabSelected] = useState<number>(actualTab);
  const router = useRouter();

  useEffect(() => {
    setTabSelected(actualTab ?? 0);
  }, [actualTab]);

  useEffect(() => {
    onTabChange?.(tabs[tabSelected]);
  }, [tabSelected]);

  useEffect(() => {
    const pathName = window.location.pathname;
    const basePath = paths.dashboard.billing;

    if (pathName === basePath) {
      router.replace(`${pathName}?tab=${tabs[tabSelected].key ?? ""}`);
    }
  }, [tabSelected]);

  return (
    <Box>
      <Box sx={{ padding: "0 24px", ...sx }}>
        <Stack
          justifyContent={
            align == "center"
              ? "center"
              : align == "left"
              ? "flex-start"
              : "flex-end"
          }
          direction={"row"}
        >
          {tabs.map((tab, index) => {
            return tab.visible == true ||
              tab.visible == null ||
              tab.visible == undefined ? (
              <TabItemNLC
                onClick={() => setTabSelected(index)}
                selected={tabSelected === index}
                statusColor={color}
                variant={variant}
                size={size}
                key={generateRandomUUID()}
                {...tab}
              />
            ) : null;
          })}
        </Stack>
      </Box>
      {showComponents && tabs.length ? (
        <>
          <Divider />
          <Box>{tabs[tabSelected].component}</Box>
        </>
      ) : null}
    </Box>
  );
};

export default TabsNLC;
