import { useContext } from "react";
import OnboardingContext, {
  OnboardingContextProps,
} from "../../context/onboardingContext";

const useOnboarding = (): OnboardingContextProps => {
  const context = useContext(OnboardingContext);

  return { ...context };
};

export default useOnboarding;
