import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";
import Error401Page from "src/pages/401";
import Error404Page from "src/pages/404";
import Error500Page from "src/pages/500";
import { authRoutes } from "./auth";
import { authDemoRoutes } from "./auth-demo";
import { componentsRoutes } from "./components";
import { dashboardRoutes } from "./dashboard";
import { Layout as DashboardLayout } from "src/layouts/dashboard";
import { lazy } from "react";
import CheckoutTemplate from "src/modules/checkout/templates/checkoutTemplate";
import OnboardingTemplate from "src/modules/onboarding/templates/onboardingTemplate";

const IndexPage = lazy(() => import("src/pages/dashboard/index"));

export const routes: RouteObject[] = [
  {
    element: (
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      ...componentsRoutes,
    ],
  },
  ...authRoutes,
  ...authDemoRoutes,
  ...dashboardRoutes,
  {
    path: "dashboard",
    children: [{ path: "onboarding", element: <OnboardingTemplate /> }],
  },
  {
    path: "checkout",
    element: <CheckoutTemplate />,
  },
  {
    path: "401",
    element: <Error401Page />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "500",
    element: <Error500Page />,
  },
  {
    path: "*",
    element: <Error404Page />,
  },
];
