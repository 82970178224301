import { Stack, Typography, useTheme } from "@mui/material";

const OnboardingStep1 = () => {
  const { palette } = useTheme();
  return (
    <Stack direction={"column"} justifyContent={"center"} alignItems={"center"}>
      <img
        style={{ width: "160px", height: "160px" }}
        src="/assets/onboarding/step1.png"
      />
      <Typography
        marginTop={"24px"}
        fontSize={"24px"}
        fontWeight={600}
        textAlign={"center"}
      >
        We're thrilled to welcome you on board
      </Typography>
      <Typography
        color={palette.text.secondary}
        fontSize={"14px"}
        fontWeight={400}
        textAlign={"center"}
      >
        And we are eager to learn more about you in the next few steps.
      </Typography>
    </Stack>
  );
};

export default OnboardingStep1;
