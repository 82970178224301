import { useContext } from "react";
import GlobalContext, {
  GlobalContextInterface,
} from "../context/globalContext";

const useGlobalConfig = (): GlobalContextInterface => {
  const config = useContext(GlobalContext);
  return { ...config };
};

export default useGlobalConfig;
