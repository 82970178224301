import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import React, { CSSProperties, ReactNode, useRef } from "react";

import { useClickAway } from "react-use";
import AvatarNLC from "../avatarNLC";

interface DropdownUserHeaderNLCProps {
  avatar: string;
  name: string;
  email: string;
  isOnline?: boolean;
}

interface DropdownNLCProps {
  activator?: ReactNode | ReactNode[];
  open?: boolean;
  changeDropdown?: (open: boolean) => void;
  align?: "left" | "right";
  width?: string;
  overflow?: "visible" | "hidden" | "scroll" | "auto";
  children?: ReactNode;
  ref?: any;
  userHeader?: DropdownUserHeaderNLCProps;
  title?: string;
  dropdownPosition?: "top" | "bottom";
  maxHeight?: string;
  sx?: CSSProperties;
}
const DropdownNLC = ({
  children,
  open,
  align = "left",
  width,
  overflow = "auto",
  activator,
  changeDropdown,
  userHeader,
  title,
  dropdownPosition = "bottom",
  sx,
  maxHeight,
}: DropdownNLCProps) => {
  const ref = useRef(null);
  useClickAway(ref, () => {
    if (open) {
      changeDropdown?.(false);
    }
  });
  const { palette } = useTheme();
  return (
    <Box sx={{ ...sx }} ref={ref}>
      {dropdownPosition == "bottom" && activator ? activator : null}
      {open && (
        <Box position={"relative"}>
          <Box
            sx={{
              ...sx,
              boxShadow:
                "0px 0px 0px 0px rgba(0, 0, 0, 0.03), 0px 2px 4px 0px rgba(0, 0, 0, 0.03), 0px 8px 8px 0px rgba(0, 0, 0, 0.03), 0px 18px 11px 0px rgba(0, 0, 0, 0.03), 0px 32px 13px 0px rgba(0, 0, 0, 0.01), 0px 51px 14px 0px rgba(0, 0, 0, 0.00)",
            }}
            left={align == "left" ? "0px" : "auto"}
            right={align == "right" ? "0px" : "auto"}
            top={dropdownPosition == "bottom" ? "8px" : "auto"}
            bottom={dropdownPosition == "top" ? "8px" : "auto"}
            minWidth={width ? width : "280px"}
            maxWidth={width ? width : "350px"}
            borderRadius={"12px"}
            maxHeight={maxHeight ? maxHeight : "auto"}
            overflow={overflow}
            border={`0.5px solid ${palette.neutral[50]} `}
            bgcolor={palette.background.paper}
            boxShadow={"0px 0px 0px rgba(0, 0, 0, 0.3)"}
            position={"absolute"}
            zIndex={50}
          >
            {userHeader && (
              <>
                <Stack
                  padding={"12px 16px"}
                  alignItems={"center"}
                  direction={"row"}
                  spacing={"12px"}
                >
                  <AvatarNLC size="40px" src={userHeader.avatar} />
                  <Box>
                    <Typography
                      color={palette.text.primary}
                      fontSize={14}
                      fontWeight={600}
                      lineHeight={"20px"}
                    >
                      {userHeader.name}
                    </Typography>
                    <Typography
                      color={palette.text.secondary}
                      fontSize={13}
                      fontWeight={400}
                      lineHeight={"20px"}
                    >
                      {userHeader.email}
                    </Typography>
                  </Box>
                </Stack>
                <Divider />
              </>
            )}
            {title && !userHeader ? (
              <>
                <Box padding={"12px 16px"}>
                  <Typography
                    color={palette.text.primary}
                    fontSize={14}
                    fontWeight={600}
                    lineHeight={"20px"}
                  >
                    {title}
                  </Typography>
                </Box>
                <Divider />
              </>
            ) : null}
            {children}
          </Box>
        </Box>
      )}
      {dropdownPosition == "top" && activator ? activator : null}
    </Box>
  );
};

export default DropdownNLC;
