import { Box, Stack, Typography, useTheme } from "@mui/material";

import AvatarNLC from "../avatarNLC";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import auroraTheme from "src/aurora/themes";

interface TagNLCProps {
  text?: string;
  size?: "sm" | "md" | "lg";
  color?: "primary" | "secondary" | "info" | "warning" | "success" | "error";
  isColor?: boolean;
  img?: string;
  hasHover?: boolean;
  leftIcon?: IconProp;
  rightIcon?: IconProp;
  disabled?: boolean;
  onClick?: () => void;
  hasClose?: boolean;
  hasQuantity?: boolean;
  quantity?: number;
  isRefactored?: boolean;
}

const TagNLC = ({
  text,
  color = "primary",
  size,
  hasHover = true,
  img,
  leftIcon,
  rightIcon,
  disabled = false,
  onClick,
  hasClose = true,
  hasQuantity = false,
  quantity,
  isColor = false,
  isRefactored = false,
}: TagNLCProps) => {
  const theme = useTheme();

  const getColor = () => {
    switch (theme.palette.mode) {
      case "dark":
        return {
          bgNormal: color + ".50",
          bgHover: color + ".100",
          borderNormal: color + ".500",
          borderHover: color + ".600",
          borderDisabled: color + ".200",
          fontColorNormal: color + ".500",
          fontColorHover: color + ".600",
          fontColorDisabled: color + ".200",
          iconColor: theme.palette[color][500],
          iconColorDisabled: theme.palette[color][200],
          quantityColor: color + ".500",
          quantityColorHover: color + ".600",
          quantityColorDisabled: color + ".200",
        };
      default:
        return {
          bgNormal: color + ".50",
          bgHover: color + ".100",
          borderNormal: color + ".500",
          borderHover: color + ".600",
          borderDisabled: color + ".200",
          fontColorNormal: color + ".500",
          fontColorHover: color + ".600",
          fontColorDisabled: color + ".200",
          iconColor: theme.palette[color][500],
          iconColorHover: theme.palette[color][600],
          iconColorDisabled: theme.palette[color][200],
          quantityColor: color + ".500",
          quantityColorHover: color + ".600",
          quantityColorDisabled: color + ".200",
        };
    }
  };

  const getSizes = () => {
    switch (size) {
      case "sm":
        return {
          fontSize: "12px",
          px: "8px",
          py: "3px",
          rounded: "6px",
          iconSize: "xs",
          quantity: "16px",
        };
      case "md":
        return {
          fontSize: "14px",
          px: "9px",
          py: "4px",
          rounded: "6px",
          iconSize: "xs",
          quantity: "18px",
        };
      case "lg":
        return {
          fontSize: "14px",
          px: "10px",
          py: "5px",
          rounded: "6px",
          iconSize: "md",
          quantity: "20px",
        };
      default:
        return {
          fontSize: "12px",
          px: "8px",
          py: "3px",
          rounded: "6px",
          iconSize: "xs",
        };
    }
  };

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick?.();
      }}
      sx={{
        cursor: onClick ? "pointer" : "default",
        backgroundColor: isRefactored ? "transparent" : getColor().bgNormal,
        borderWidth: `1px `,
        borderStyle: "solid",
        borderColor: disabled
          ? getColor().borderDisabled
          : isRefactored
          ? auroraTheme.mixins.components.tag.borderColor
          : getColor().borderNormal,
        px: getSizes().px,
        py: getSizes().py,
        borderRadius: isRefactored ? "6px" : "4px",
        "&:hover":
          hasHover && !disabled && onClick && !isRefactored
            ? {
                backgroundColor: getColor().bgHover,
                borderColor: getColor().borderHover,
              }
            : isRefactored
            ? {
                backgroundColor:
                  auroraTheme.mixins.components.tag.backgroundColor,
              }
            : {},
      }}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={"5px"}>
        {img && <AvatarNLC src={img} size={getSizes().quantity} />}
        {leftIcon && (
          <FontAwesomeIcon
            size={getSizes().iconSize as SizeProp}
            color={
              disabled ? getColor().iconColorDisabled : getColor().iconColor
            }
            icon={leftIcon}
          />
        )}
        <Typography
          color={
            disabled
              ? getColor().fontColorDisabled
              : isRefactored
              ? auroraTheme.mixins.components.tag.textColor
              : getColor().fontColorNormal
          }
          fontWeight={isRefactored ? 500 : 400}
          fontSize={getSizes().fontSize}
        >
          {text}
        </Typography>
        {(hasQuantity || isColor) && (
          <Box
            sx={{
              width: getSizes().quantity,
              height: getSizes().quantity,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              backgroundColor: isColor
                ? text
                : disabled
                ? getColor().quantityColorDisabled
                : getColor().quantityColor,
              "&:hover":
                hasHover && !disabled && onClick
                  ? { backgroundColor: getColor().quantityColorHover }
                  : {},
            }}
          >
            <Typography
              sx={{
                height: "100%",
                color: "white",
                fontWeight: "500",
              }}
              fontSize={getSizes().fontSize}
            >
              {isColor ? "" : quantity}
            </Typography>
          </Box>
        )}
        {rightIcon && (
          <FontAwesomeIcon
            size={getSizes().iconSize as SizeProp}
            color={
              disabled ? getColor().iconColorDisabled : getColor().iconColor
            }
            icon={rightIcon}
          />
        )}
        {hasClose && (
          <FontAwesomeIcon
            size={getSizes().iconSize as SizeProp}
            color={
              disabled
                ? getColor().iconColorDisabled
                : isRefactored
                ? auroraTheme.mixins.components.tag.iconColor
                : getColor().iconColor
            }
            icon={["fas", "close"]}
          />
        )}
      </Stack>
    </Box>
  );
};

export default TagNLC;
