import type { PaletteOptions } from "@mui/material";
import { common } from "@mui/material/colors";

import { neutral } from "../colors";

// interface Config {
//   colorPreset?: ColorPreset;
//   contrast?: Contrast;
// }

export const createPalette = (): PaletteOptions => {
  return {
    mode: "light",
    grey: {
      50: "#EEEFF2",
      100: "#DCDFE4",
      200: "#C0C4CE",
      300: "#9DA4B4",
      400: "#80899D",
      500: "#667085",
      600: "#535B6C",
      700: "#353A45",
      800: "#262931",
      900: "#1A1D22",
    },
    primary: {
      main: "#6B04FF",
      50: "#F0E6FF",
      200: "#C49BFF",
      100: "#E1CDFF",
      300: "#A668FF",
      400: "#8936FF",
      500: "#6B04FF",
      600: "#5100E5",
      700: "#1E00B2",
      800: "#00007F",
      900: "#00004C",
      light: "#E1CDFF",
      dark: "#00007F",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#667085",
      50: "#EEEFF2",
      100: "#DCDFE4",
      200: "#C0C4CE",
      300: "#9DA4B4",
      400: "#80899D",
      500: "#667085",
      600: "#535B6C",
      700: "#353A45",
      800: "#262931",
      900: "#1A1D22",
      light: "#b6b7bf",
      dark: "#14151c",
      contrastText: "#ffffff",
    },
    info: {
      main: "#2E90FA",
      50: "#EFF8FF",
      100: "#D1E9FF",
      200: "#B2DDFF",
      300: "#84CAFF",
      400: "#53B1FD",
      500: "#2E90FA",
      600: "#1570EF",
      700: "#175CD3",
      800: "#1849A9",
      900: "#194185",
      light: "#46a1fc",
      dark: "#133b80",
      contrastText: "#ffffff",
    },
    warning: {
      main: "#F9AC48",
      50: "#FEF7ED",
      100: "#FEEEDA",
      200: "#FDDEB6",
      300: "#FBC988",
      400: "#F9AC48",
      500: "#F79009",
      600: "#D37A07",
      700: "#A56005",
      800: "#6E4003",
      900: "#2E1B01",
      light: "#f59e3a",
      dark: "#e84104",
      contrastText: "#ffffff",
    },
    error: {
      main: "#F04438",
      50: "#FEF1F0",
      100: "#FDE3E1",
      200: "#FBC8C4",
      300: "#F8ACA6",
      400: "#F36E64",
      500: "#F04438",
      600: "#EA2012",
      700: "#B7190E",
      800: "#7A1109",
      900: "#3D0805",
      light: "#f44b4b",
      dark: "#d9221d",
      contrastText: "#ffffff",
    },
    success: {
      main: "#10B981",
      50: "#EAFDF7",
      100: "#D5FBEE",
      200: "#AAF8DE",
      300: "#6BF2C5",
      400: "#14E19D",
      500: "#10B981",
      600: "#0D9568",
      700: "#0A7350",
      800: "#075139",
      900: "#032218",
      light: "#2cbf89",
      dark: "#05704e",
      contrastText: "#ffffff",
    },

    neutral,
    text: {
      primary: "#111927",
      secondary: "#667085",
      disabled: "rgba(17,25,39,0.38)",
    },
    background: {
      default: "#f8f9fa",
      paper: common.white,
    },
  };
};
