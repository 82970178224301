import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { CSSProperties } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import BadgeNLC from "src/modules/globals/components/badgeNLC";
import ButtonNLC from "src/modules/globals/components/buttonNLC";
import CheckboxNLC from "src/modules/globals/components/checkboxNLC";

interface MostImportantComponentProps {
  isDraggable?: boolean;
  title: string;
  subtitle: string;
  sx?: CSSProperties;
  objects?: { name: string; label: string }[];
  onChangeOrder?: (objects: { name: string; label: string }[]) => void;
  onSelectOne?: (object: { name: string; label: string }) => void;
  selected?: { name: string; label: string }[];
}

const MostImportantComponent = ({
  isDraggable,
  sx,
  title,
  subtitle,
  objects,
  onChangeOrder,
  onSelectOne,
  selected,
}: MostImportantComponentProps) => {
  const { palette } = useTheme();
  const handleOrderChange = (result: any) => {
    const items = objects;
    const [reorderedItem] = items!.splice(result.source.index, 1);
    items!.splice(result.destination.index, 0, reorderedItem);
    onChangeOrder && onChangeOrder?.(items!);
  };

  const handleSelectOne = (object: { name: string; label: string }) => {
    onSelectOne && onSelectOne?.(object);
  };

  return (
    <Box maxWidth={"245px"} sx={{ ...sx }}>
      <Typography fontWeight={500}>{title}</Typography>
      <Typography
        marginTop={"8px"}
        fontSize={"14px"}
        height={"60px"}
        color={palette.text.secondary}
        fontWeight={400}
        marginBottom={"16px"}
      >
        {subtitle}
      </Typography>
      <DragDropContext onDragEnd={handleOrderChange}>
        <Droppable droppableId="itens">
          {(provided) => (
            <div
              style={{
                maxHeight: "182px",
                overflow: "auto",
                marginTop: "16px",
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {objects?.map((item, index) => (
                <Draggable
                  isDragDisabled={!isDraggable}
                  key={item.label}
                  draggableId={item.label}
                  index={index}
                >
                  {(provided) => (
                    <Stack
                      alignItems={"center"}
                      spacing={"8px"}
                      padding={"6px 8px"}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      direction={"row"}
                      sx={{
                        border: `1px solid ${palette.grey[100]}`,
                        borderRadius: "4px",
                        marginBottom: "8px",
                      }}
                    >
                      {isDraggable && (
                        <FontAwesomeIcon icon={["far", "grip-dots-vertical"]} />
                      )}
                      {isDraggable && (
                        <Stack
                          sx={{
                            height: "24px",
                            width: "24px",
                            border: `1px solid ${palette.primary[500]}`,
                            borderRadius: "4px",
                            backgroundColor: palette.primary[50],
                            color: palette.primary[500],
                            fontSize: "11px",
                          }}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          {index + 1}
                        </Stack>
                      )}

                      {!isDraggable && (
                        <CheckboxNLC
                          value={
                            selected?.some((val) => item.name == val.name) ??
                            false
                          }
                          onChange={() => handleSelectOne(item)}
                        />
                      )}
                      <Typography
                        fontSize={"14px"}
                        color={palette.text.secondary}
                      >
                        {item.name}
                      </Typography>
                    </Stack>
                  )}
                </Draggable>
              ))}
              {!provided.placeholder!}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default MostImportantComponent;
