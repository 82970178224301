import type { FC } from "react";

import { Badge, Box, IconButton, Tooltip } from "@mui/material";
import { usePopover } from "src/hooks/use-popover";
import { NotificationsPopover } from "./notifications-popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useGlobalConfig from "src/modules/globals/hooks/useGlobalConfig";

export const NotificationsButton: FC = () => {
  const popover = usePopover<HTMLButtonElement>();
  const { handleRemoveOne, handleMarkAllAsRead, notifications, unread } =
    useGlobalConfig();

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton ref={popover.anchorRef} onClick={popover.handleOpen}>
          <Badge color="error" badgeContent={unread}>
            <FontAwesomeIcon icon={["far", "bell"]} size="xs" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Box sx={{ position: "relative" }}>
        <NotificationsPopover
          anchorEl={popover.anchorRef.current}
          notifications={notifications}
          onClose={popover.handleClose}
          onMarkAllAsRead={handleMarkAllAsRead}
          onRemoveOne={handleRemoveOne}
          open={popover.open}
        />
      </Box>
    </>
  );
};
