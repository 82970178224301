import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import GlobalContext from "../context/globalContext";
import { useAuth } from "src/hooks/use-auth";
import { Taxonomies } from "../interfaces/taxonomies";
import GlobalRepository from "../repository/globalRepostiory";
import { Profile } from "../interfaces/profile";
import { IMPERSONATE_STORAGE_KEY } from "src/modules/core/const/storageKeys";
import { Notification } from "../interfaces/notification";
import { initialNotifications } from "../layout/dashboard/components/notifications-button/notifications";
import Permissions from "../interfaces/permissions";
import { useRouter } from "src/hooks/use-router";
import { AxiosError } from "axios";

const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const [taxonomies, setTaxonomies] = useState<Taxonomies>();
  const [permissions, setPermissions] = useState<Permissions[]>([]);
  const [profile, setProfile] = useState<Profile>();
  const router = useRouter();
  const [impersonated, setImpersonated] = useState<boolean>(false);
  const [notifications, setNotifications] =
    useState<Notification[]>(initialNotifications);

  const {
    fetchTaxonomies,
    getUserProfile,
    startImpersonate,
    getUserPermissions,
  } = GlobalRepository();
  const { isAuthenticated, isInitialized } = useAuth();

  const handleGetUserPermissions = useCallback(async () => {
    const res = await getUserPermissions();
    if (res.isSuccess) {
      setPermissions(res.data?.data ?? []);
    }
  }, []);

  const handleFetchTaxonomies = async () => {
    fetchTaxonomies().then((res) => {
      if (res.isSuccess) {
        setTaxonomies(res.data?.data);
      }
    });
  };

  const unread = useMemo((): number => {
    return notifications.reduce(
      (acc, notification) => acc + (notification.read ? 0 : 1),
      0,
    );
  }, [notifications]);

  const handleMarkAllAsRead = useCallback((): void => {
    setNotifications((prevState) => {
      return prevState.map((notification) => ({
        ...notification,
        read: true,
      }));
    });
  }, []);

  const handleRemoveOne = useCallback((notificationId: string): void => {
    setNotifications((prevState) => {
      return prevState.filter(
        (notification) => notification.id !== notificationId,
      );
    });
  }, []);

  const handleFetchUserProfile = async () => {
    getUserProfile().then((res) => {
      if (res.isSuccess) {
        setProfile(res.data?.data);
      }
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      handleFetchTaxonomies();
      handleFetchUserProfile();
      handleGetUserPermissions();
      if (sessionStorage.getItem(IMPERSONATE_STORAGE_KEY)) {
        setImpersonated(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const handleImpersonate = async (username: string) => {
    if (!allowedTo("App\\Actions\\ImpersonatingService\\ImpersonateUserAction"))
      return;

    const res = await startImpersonate(username);
    if (res.isSuccess) {
      setImpersonated(true);
      if (res.data?.token && res.data?.token !== "") {
        sessionStorage.setItem(IMPERSONATE_STORAGE_KEY, res.data?.token);
        router.refresh();
      }
      handleFetchUserProfile();
    }
  };
  const handleStopImpersonate = async () => {
    sessionStorage.removeItem(IMPERSONATE_STORAGE_KEY);
    setImpersonated(false);
    router.refresh();
  };

  const allowedTo = (action: string) => {
    return permissions.some((perm) => perm.action == action);
  };

  const handleCatchAxiosError = (error: AxiosError) => {
    return {
      data: {},
      isSuccess: error?.status === 403,
      isError: true,
      error: error?.message,
    };
  };

  return (
    <GlobalContext.Provider
      value={{
        isAuthenticated,
        profile,
        isInitialized,
        taxonomies,
        handleImpersonate,
        handleStopImpersonate,
        impersonated,
        notifications,
        unread,
        handleMarkAllAsRead,
        handleRemoveOne,
        permissions,
        allowedTo,
        handleCatchAxiosError,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
