import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography } from "@mui/material";
import { Logo } from "src/components/logo";
import { LogoSamsung } from "src/components/logos/logo-samsung";
import { LogoVisma } from "src/components/logos/logo-visma";
import { LogoBolt } from "src/components/logos/logo-bolt";
import { LogoAws } from "src/components/logos/logo-aws";
import { LogoAccenture } from "src/components/logos/logo-accenture";
import { LogoAtt } from "src/components/logos/logo-att";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";

interface LayoutProps {
  children: ReactNode;
}

export const Layout: FC<LayoutProps> = (props) => {
  const { children } = props;

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        display: "flex",
        flex: "1 1 auto",
        flexDirection: {
          xs: "column-reverse",
          md: "row",
        },
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "neutral.800",
          backgroundImage: 'url("/assets/gradient-bg-2.svg")',
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          color: "common.white",
          display: "flex",
          flex: {
            xs: "0 0 auto",
            md: "1 1 auto",
          },
          justifyContent: "center",
          p: {
            xs: 4,
            md: 8,
          },
        }}
      >
        <Box maxWidth="md">
          <Typography sx={{ mb: 1 }} variant="h4">
            Welcome to NLC!
          </Typography>
          <Typography color="white" sx={{ mb: 4 }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget
            porttitor magna. Vivamus nec condimentum urna. Ut viverra mauris at
            convallis posuere. Phasellus maximus felis fermentum, fermentum
            lorem pellentesque, semper urna.
          </Typography>
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            Join 6,000+ creative-thinking companies:
          </Typography>
          <Stack
            alignItems="center"
            direction="row"
            flexWrap="wrap"
            gap={4}
            sx={{
              color: "text.primary",
              "& > *": {
                color: "neutral.400",
                flex: "0 0 auto",
              },
            }}
          >
            <LogoSamsung />
            <LogoVisma />
            <LogoBolt />
            <LogoAws />
            <LogoAccenture />
            <LogoAtt />
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "background.paper",
          display: "flex",
          flex: {
            xs: "1 1 auto",
            md: "0 0 auto",
          },
          flexDirection: "column",
          justifyContent: {
            md: "center",
          },
          maxWidth: "100%",
          p: {
            xs: 4,
            md: 8,
          },
          width: {
            md: 600,
          },
        }}
      >
        <div>
          <Box sx={{ mb: 4, pl: 3 }}>
            <Stack
              alignItems="center"
              component={RouterLink}
              direction="row"
              display="inline-flex"
              href={paths.index}
              spacing={1}
              sx={{ textDecoration: "none" }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  height: 42,
                  width: 150,
                }}
              >
                <img
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                  src="/assets/logos/newNLC.png"
                  alt="NLC"
                />
              </Box>
            </Stack>
          </Box>
          {children}
        </div>
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
