import GeneralConfigContext from "../../context/generalConfigContext";

import { ReactNode } from "react";

const GeneralConfigProvider = ({ children }: { children: ReactNode }) => {
  return (
    <GeneralConfigContext.Provider value={{}}>
      {children}
    </GeneralConfigContext.Provider>
  );
};

export default GeneralConfigProvider;
