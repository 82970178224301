import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ReactNode, useState } from "react";

import AvatarNLC from "../avatarNLC";
import auroraTheme from "src/aurora/themes";

interface BadgeNLCProps {
  text?: string;
  size?: "sm" | "md";
  color?: "primary" | "secondary" | "info" | "warning" | "success" | "error";
  img?: string;
  isOnline?: boolean;
  hasHover?: boolean;
  icon?: ReactNode;
  disabled?: boolean;
  forceColor?: string;
}

interface BadgeColorScheme {
  color: string;
  colorHover: string;
  colorActive: string;
  colorDisabled: string;
  borderColor: string;
  borderColorActive: string;
  borderColorDisabled?: string;
  borderColorHover?: string;
}

interface BadgeSizeScheme {
  fontSize: string;
  px: string;
  py: string;
}

const BadgeNLC = ({
  text,
  color = "primary",
  size = "sm",
  hasHover = true,
  img,
  isOnline = false,
  icon,
  disabled = false,
  forceColor,
}: BadgeNLCProps) => {
  const theme = useTheme();
  const [hover, setHover] = useState(false);
  const getColor = (): BadgeColorScheme => {
    switch (theme.palette.mode) {
      case "dark":
        return {
          color: theme.palette[color][500],
          colorHover: theme.palette[color][500],
          colorActive: theme.palette[color][500],
          colorDisabled: theme.palette[color][500],
          borderColor: theme.palette[color][500],
          borderColorActive: theme.palette[color][500],
        };
      default:
        return {
          color:
            auroraTheme.mixins.components.badge.color ??
            theme.palette[color][500],
          colorHover: theme.palette[color][600],
          colorActive: theme.palette[color][500],
          colorDisabled: theme.palette[color][200],
          borderColor:
            auroraTheme.mixins.components.badge.borderColor ??
            theme.palette[color][300],
          borderColorActive: theme.palette[color][400],
          borderColorDisabled: theme.palette[color][200],
          borderColorHover: theme.palette[color][600],
        };
    }
  };

  const getSize = (): BadgeSizeScheme => {
    switch (size) {
      case "sm":
        return {
          fontSize: "12px",
          px: "8px",
          py: "3px",
        };
      default:
        return {
          fontSize: "14px",
          px: "12px",
          py: "6px",
        };
    }
  };

  const insideColor = hover ? getColor().borderColorHover : getColor().color;

  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        cursor: "pointer",
        width: "fit-content",
        backgroundColor: auroraTheme.mixins.components.badge.backgroundColor,
        border: `1px solid ${
          forceColor
            ? forceColor
            : disabled
            ? getColor().borderColorDisabled
            : getColor().borderColor
        }`,
        padding: `${getSize().py} ${getSize().px}`,
        borderRadius: "50px",
        "&:hover":
          hasHover && !disabled && !forceColor
            ? {
                borderColor: getColor().borderColorHover,
              }
            : {},
        "&:active": {
          borderColor: disabled
            ? getColor().borderColorDisabled
            : getColor().borderColorActive,
        },
      }}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={"4px"}>
        {img && (
          <Box>
            <AvatarNLC src={img} size="16px" />
          </Box>
        )}
        {isOnline && (
          <Box
            sx={{
              width: "10px",
              height: "10px",
              borderRadius: "3px",
              backgroundColor: forceColor
                ? forceColor
                : disabled
                ? getColor().borderColorDisabled
                : insideColor,
            }}
          ></Box>
        )}

        {icon && <Box>{icon}</Box>}
        <Typography
          fontWeight={600}
          sx={{
            color: forceColor
              ? theme.palette.secondary[600]
              : disabled
              ? getColor().borderColorDisabled
              : insideColor,
          }}
          fontSize={getSize().fontSize}
        >
          {text}
        </Typography>
      </Stack>
    </Box>
  );
};

export default BadgeNLC;
