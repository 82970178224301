import { Container, Stack } from "@mui/material";
import CheckoutSummary from "../components/checkoutSummary";
import CheckoutBasicInfo from "../components/checkoutBasicInfo";

const CheckoutTemplate = () => {
  return (
    <Container>
      <Stack
        direction={"row"}
        marginTop={"40px"}
        marginBottom={"40px"}
        spacing={"24px"}
      >
        <CheckoutSummary sx={{ height: "100%" }} />
        <CheckoutBasicInfo sx={{ height: "100%" }} />
      </Stack>
    </Container>
  );
};

export default CheckoutTemplate;
